"use client";

import React from "react"
import styled from "styled-components"
import { common } from "components/atoms/_atoms"
import { BaseStyles } from "types/base-styles"

function Spinner({
  white,
  size,
  color,
  borderWidth,
  ...rest
}: {
  white?: boolean
  size?: string
  color?: string
  borderWidth?: number
}) {
  return (
    <Wrapper className="spinner_wrapper" {...rest}>
      <Content>
        <StyledSpinner size={size} color={white ? "white" : color} borderWidth={borderWidth}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </StyledSpinner>
      </Content>
    </Wrapper>
  )
}

Spinner.propTypes = {}

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div<BaseStyles>`
  display: ${({ display = "block" }) => display};
  ${common};
`

const StyledSpinner = styled.div<BaseStyles>`
  display: inline-block;
  position: relative;
  width: ${({ size }) => size || "30px"};
  height: ${({ size }) => size || "30px"};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ size }) => size || "30px"};
    height: ${({ size }) => size || "30px"};
    margin: 0px;
    border: ${({ borderWidth }) => borderWidth || "4"}px solid ${({ theme, color = "primary" }) =>
  theme.color[color] ?? color};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme, color = "primary" }) =>
      theme.color[color] ?? color} transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(1) {
      animation-delay: -0.3s;
    }

    &:nth-child(1) {
      animation-delay: -0.15s;
    }

    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
`

export default Spinner
