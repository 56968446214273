"use client"

import React from "react"
import styled, { css } from "styled-components"
import { Absolute, common } from "components/atoms/_atoms"
import Icon from "components/atoms/icon"
import { BaseStyles } from "types/base-styles"
import Spinner from "components/atoms/spinner"

export type IconButtonProps = BaseStyles & {
  icon: string
  size?: string
  iconSize?: string
  onClick?: () => void
  background?: string
  color?: string
  hoverBackground?: string
  hoverColor?: string
  style?: object
  loading?: boolean
  disabled?: boolean
}

export const IconButton = ({
  icon,
  size = "XXXL",
  iconSize = "S",
  onClick,
  color,
  style,
  loading,
  disabled,
  ...rest
}: IconButtonProps) => {
  return (
    <StyledIconButton disabled={disabled} size={size} iconSize={iconSize} onClick={onClick} style={style} {...rest}>
      <Icon color={color} icon={icon} opacity={loading ? 0 : 1} />
      {loading && (
        <Absolute
          width="100%"
          height="100%"
          left="0"
          top="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner color={color} size="20px" />
        </Absolute>
      )}
    </StyledIconButton>
  )
}

const StyledIconButton = styled.button<
  BaseStyles & {
    background?: string
    color?: string
    hoverBackground?: string
    hoverColor?: string
    size: string
    iconSize: string
  }
>`
    ${common};
    position: relative;
    cursor: pointer;
    border: none;
    background: ${({ theme, background = "background" }) => theme.color[background] ?? background};
    color: ${({ theme, color = "second" }) => theme.color[color] ?? color} !important;
    border-radius: ${({ theme, borderRadius }) =>
            borderRadius ? theme.variable[borderRadius] ?? borderRadius : "50%"};
    width: ${({ theme, size, background }) =>
            background === "none" ? "auto" : theme.font[size] ?? size};
    height: ${({ theme, size, background }) =>
            background === "none" ? "auto" : theme.font[size] ?? size};
    min-width: ${({ theme, size, background }) =>
            background === "none" ? "auto" : theme.font[size] ?? size};
    min-height: ${({ theme, size, background }) =>
            background === "none" ? "auto" : theme.font[size] ?? size};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.3s;

    svg {
        width: ${({ theme, iconSize }) => theme.font[iconSize] ?? iconSize};
        min-width: ${({ theme, iconSize }) => theme.font[iconSize] ?? iconSize};
        display: block !important;
    }

    ${({ disabled }) =>
            disabled &&
            css`
                opacity: 0.8;
                cursor: not-allowed;
            `};

    &:hover {
        ${({ theme, hoverColor }) =>
                hoverColor &&
                css`
                    svg {
                        color: ${theme.color[hoverColor] ?? hoverColor};
                    }
                `};

        ${({ theme, hoverBackground }) =>
                hoverBackground &&
                css`
                    background: ${theme.color[hoverBackground] ?? hoverBackground};
                `};
    }
`
