"use client"

import React, { ReactNode } from "react"
import styled, { css } from "styled-components"
import { BaseStyles } from "types/base-styles"
import { common } from "components/atoms/_atoms"
import { Label } from "components/atoms/typography"

export type PProps = BaseStyles & {
  children: ReactNode
  label?: string
  spanColor?: string
  labelColor?: string
  small?: boolean
  fontSize?: string
}
export const P = ({
  children,
  label,
  labelColor = "third",
  small,
  fontSize,
  spanColor,
  ...rest
}: PProps) => {
  if (label) {
    return (
      <Wrapper {...rest}>
        {label && (
          <Label type="third" color={labelColor} marginBottom="XXS">
            {label}
          </Label>
        )}
        <StyledP small={small} fontSize={fontSize} margin="0" spanColor={spanColor}>
          {children}
        </StyledP>
      </Wrapper>
    )
  }
  return (
    <StyledP small={small} fontSize={fontSize} spanColor={spanColor} {...rest}>
      {children}
    </StyledP>
  )
}

export default P

export const pStyles = css<
  BaseStyles & {
    whiteSpace?: string
    textOverflow?: string
    spanColor?: string
  }
>`
  font-family: "Manrope" !important;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.space.S};
  ${common};
  font-size: ${({ theme, fontSize = "L" }) => theme.font[fontSize]};
  color: ${({ theme, color = "second" }) => theme.color[color] ?? color};
  line-height: normal;
  text-align: ${({ textAlign }) => textAlign || "left"};
  max-width: ${({ maxWidth }) => maxWidth || "none"};
  white-space: ${({ whiteSpace }) => whiteSpace};
  text-overflow: ${({ textOverflow }) => textOverflow};
  overflow: ${({ overflow }) => overflow};
  font-weight: ${({ theme, fontWeight = "light" }) => theme.font[fontWeight]};

  span,
  b {
    font-weight: ${({ theme, fontWeight = "bold" }) => theme.font[fontWeight]};
    color: ${({ theme, spanColor = "primary" }) => theme.color[spanColor] ?? spanColor};
  }

  // button {
  //   background: none;
  //   padding: 0;
  //   border: none;
  //   color: ${({ theme }) => theme.color.primary};
  // }
  //
  // a {
  //   color: ${({ theme }) => theme.color.primary};
  // }
`

const Wrapper = styled.p<BaseStyles>`
  margin-bottom: ${({ theme }) => theme.space.S};
  ${common};
`

const StyledP = styled.p<
  BaseStyles & {
    spanColor?: string
  }
>`
  ${pStyles};

  ${({ small, fontSize = "M", color = "third" }) =>
    small &&
    css`
      font-size: ${({ theme }) => theme.font[fontSize]};
      color: ${({ theme }) => theme.color[color] ?? color};
    `}
`
