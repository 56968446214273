export const baseTheme = {
    color: {
        primary: "#006F3E",
        primaryDarker: "#002615",
        primaryBackground: "rgb(240, 255, 248)",
        // primary: "#12b878",
        // primaryDarker: "#1e9c68",
        // primaryBackground: "rgb(236,249,240)",
        second: "rgb(29,29,29)",
        third: "#667085",
        fourth: "rgb(239, 242, 245)",
        border: "#D0D5DD",
        background: "rgb(251,250,250)",
        backgroundDarker: "rgb(235,235,235)",
        backgroundOpacity: "rgba(0,0,0,0.2)",
        backgroundGradient: "linear-gradient(45deg, #006F3E 0%, #002F1A 100%)",
        backgroundGradientRotate: "background: linear-gradient(90deg, rgba(235,235,235, 0) 0%, rgba(235,235,235, 1) 100%)",

        white: "#fff",
        purple: "#5F2EEA",
        blue: "#007AFF",
        red: "#F8563F",
        redBackground: "rgb(253, 237, 237)",
        orange: "#df9434",
        orangeBackground: "rgb(255, 244, 229)",
        green: "#5EAF63",
        greenBackground: "rgb(237, 247, 237)",
        greenDarker: "#3a8a3f",
        orangeDarker: "#ba7114",
    },
    font: {
        XXXXL: "26px",
        XXXL: "24px",
        XXL: "20px",
        XL: "18px",
        L: "16px",
        M: "14px",
        S: "12px",
        XS: "10px",
        XXS: "5px",
        light: 500,
        normal: 600,
        bold: 700,
        extraBold: 800,
    },
    space: {
        XXL: "10rem",
        XL: "5rem",
        L: "2.5rem",
        M: "2rem",
        S: "1rem",
        XS: "10px",
        XXS: "5px",
    },
    variable: {
        borderRadius: "5px",
        maxWidth: "1600px",
        boxShadow: "0px 0px 45px 0px rgba(0,0,0,0.05)",
    },
}
