import React, { useState } from "react"
import { Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import styled from "styled-components"
import SearchHeader from "views/Panel/Search/SearchHeader"
import PanelContentLayout from "components/layout/PanelContentLayout"
import { RootState } from "store/store"
import SearchContent from "views/Panel/Search/SearchContent"
import { Box } from "components/atoms/_atoms"
import { AISourceDocument, AIService } from "services/AIService"
import { P } from "components/atoms/typography"
import SearchDataTabs from "views/Panel/Search/SearchDataTabs"
import DocumentSignatureContent from "views/Panel/Search/content/DocumentSignatureContent"
import { DocumentSignatureService } from "services/DocumentSignatureService"
import LoaderBlock from "components/shared/blocks/LoaderBlock"
import { useCustomQuery } from "hooks/useCustomQuery"
import SummaryBlock from "components/shared/blocks/SummaryBlock"
import { makeHistory, removeDuplicatesByMetadataId } from "utils/chat"

const Profile = () => {
  const {
    message: saveMessage,
    answer: saveAnswer,
    data: saveData,
  } = useSelector((state: RootState) => state.search)

  const [loading, setLoading] = useState(false)
  const [answer, setAnswer] = useState<null | string>(saveAnswer)
  const [data, setData] = useState<AISourceDocument[] | null>(saveData)

  const [selectedDocumentId, setSelectedDocumentId] = useState<null | string>(null)

  const { isLoading: countLoading, data: count } = useCustomQuery<number>({ key: ["count"] }, () =>
    DocumentSignatureService.count(),
  )

  // const fetchChat = async (id: string, message: string) => {
  //   try {
  //     setLoading(true)
  //     const { answer, question, source_documents } = await AIService.chat({
  //       message,
  //       history,
  //       types: ["act"],
  //       ids: ids,
  //     })
  //   } catch (e) {
  //     console.log(e)
  //     // SentryService.error("[ERROR ProfileOfferService.getOfferApplications]:", e)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const hasAnswer = !!answer && !loading

  return (
    <>
      <Wrapper>
        <PanelContentLayout
          icon="search"
          title="Znajdź sygnaturę"
          // text={
          //   countLoading || !count ? (
          //     <LoaderBlock height="22px" />
          //   ) : (
          //     <P margin="0">
          //       Szukaj spośrod{" "}
          //       <span>
          //         {Intl.NumberFormat("pl-PL", {
          //           style: "decimal",
          //           maximumFractionDigits: 0,
          //         }).format(count)}
          //       </span>{" "}
          //       dokumentów przy użyciu sztucznej inteligencji
          //     </P>
          //   )
          // }
        >
          <SearchHeader
            loading={loading}
            setLoading={setLoading}
            setAnswer={setAnswer}
            setSelectedDocumentId={setSelectedDocumentId}
            setData={setData}
          />
          <SearchDataTabs
            length={data?.length}
            data={data
              ?.filter(function (item, pos) {
                return data?.findIndex(el => el.metadata.id === item.metadata.id) == pos
              })
              .map(el => {
                return {
                  id: el.metadata.id,
                  title: el.metadata.title,
                }
              })}
            selectedDocumentId={selectedDocumentId}
            setSelectedDocumentId={setSelectedDocumentId}
          />
          <Box marginTop="M">
            {!selectedDocumentId ? (
              <SearchContentWithAnswer hasAnswer={hasAnswer}>
                <SearchContent
                  loading={loading}
                  data={data}
                  setSelectedDocumentId={setSelectedDocumentId}
                />
                {hasAnswer && (
                  <SummaryBlock
                    isOpen={true}
                    icon="logotype"
                    label="Parrot.ai"
                    height="max-content"
                    status="success"
                  >
                    {answer}
                  </SummaryBlock>
                )}
              </SearchContentWithAnswer>
            ) : (
              <DocumentSignatureContent id={selectedDocumentId} />
            )}
          </Box>
          {/*</SearchGrid>*/}
        </PanelContentLayout>
      </Wrapper>
      <Outlet />
    </>
  )
}

export default Profile

const SearchContentWithAnswer = styled(Box)<{
  hasAnswer?: boolean
}>`
  display: grid;
  grid-template-columns: ${({ hasAnswer }) => (hasAnswer ? "4fr 1fr" : "1fr")};
  grid-gap: ${({ theme }) => theme.space.S};
`

const Wrapper = styled(Box)`
  display: flex;
`
