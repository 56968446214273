import React from "react"
import styled, { css } from "styled-components"
import { Box } from "components/atoms/_atoms"

const SearchDataTabs = ({
  length,
  data,
  selectedDocumentId,
  setSelectedDocumentId,
}: {
  length?: number
  data?: {
    id: string
    title: string
  }[]
  selectedDocumentId: string | null
  setSelectedDocumentId: (e: string | null) => void
}) => {
  if (!data || data.length === 0) return <></>

  return (
    <Wrapper>
      <Option active={!selectedDocumentId} onClick={() => setSelectedDocumentId(null)}>
        Wyniki wyszukiwania ({length})
      </Option>
      {data.map((el, index) => (
        <Option
          key={index}
          active={selectedDocumentId === el.id}
          onClick={() => setSelectedDocumentId(el.id)}
        >
          {el.title}
        </Option>
      ))}
    </Wrapper>
  )
}

export default SearchDataTabs

const Option = styled.button<{
  active: boolean
}>`
  border-radius: 50px;
  background: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.font.M};
  font-weight: ${({ theme }) => theme.font.normal};
  color: ${({ theme }) => theme.color.secondary};
  border: none;
  padding: ${({ theme }) => theme.space.XXS} ${({ theme }) => theme.space.S};
  box-shadow: ${({ theme }) => theme.variable.boxShadow};

  ${({ theme, active }) =>
    active &&
    css`
      background: ${theme.color.primary};
      color: ${theme.color.white};
    `}
`

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  grid-gap: ${({ theme }) => theme.space.XS};
  margin-top: ${({ theme }) => theme.space.S};
  flex-wrap: wrap;
  height: max-content;
`
