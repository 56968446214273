import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "store/store"
import { ChatType } from "store/chat"

export const useChats = () => {
  const { chats } = useSelector((state: RootState) => state.chat)

  const draftChats = chats.filter(({ isDraft }) => isDraft)

  return {
    draftChat: draftChats.length > 0 ? draftChats[0] : null,
    chats: [...chats]
      .filter(({ isDraft }) => !isDraft)
      .sort((a: ChatType, b: ChatType) => b.date?.getTime() - a.date?.getTime())
  }
}

export const useChat = (id?: string) => {
  const navigate = useNavigate()
  const { chats } = useSelector((state: RootState) => state.chat)

  const index = chats.findIndex(el => el.id === id)

  if (index === -1) {
    return null
  }

  return chats[index]
}
