import React, { useCallback } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import {
  LocalStorageService,
  ProfileService,
  PublicService,
  SentryService,
  UserFolderService,
  UserService,
} from "services"
import { errorLogin, initLogin, successLogin } from "store/auth"
import { setProfile, setProfileIds } from "store/profile"
import { RootState } from "store/store"
import Modal from "components/molecules/modal"
import Alert from "components/molecules/alert"
import Input from "components/atoms/input"
import { EMAIL_REGEX } from "utils/regex"
import Button from "components/atoms/button"
import { setFolders, initGetFolders } from "store/folders"

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const { isVerifying, isLoggingIn, isLoginError } = useSelector((state: RootState) => state.auth)

  const onSubmit = useCallback(async (el: any) => {
    dispatch(initLogin())
    let user = null
    try {
      user = await PublicService.login(el.username, el.password)
      await LocalStorageService.setObject("token", user.token)
    } catch (e) {
      SentryService.error("[ERROR PublicService.login]:", e)
      LocalStorageService.removeObject("token")
      LocalStorageService.removeObject("profileId")
      dispatch(errorLogin())
      return
    }

    let profileIds = null
    try {
      profileIds = await UserService.getProfiles(user.id)
      dispatch(setProfileIds(profileIds))
    } catch (e) {
      SentryService.error("[ERROR UserService.getProfiles]:", e)
      dispatch(errorLogin())
      return
    }

    if (profileIds && profileIds[0]) {
      LocalStorageService.setObject("profileId", profileIds[0])
      try {
        const profile = await ProfileService.getProfile(profileIds[0])
        dispatch(setProfile(profile))
      } catch (e) {
        SentryService.error("[ERROR ProfileService.getProfile[:", e)
        dispatch(errorLogin())
        return
      }
    }

    dispatch(successLogin(user))

    try {
      dispatch(initGetFolders())
      const folders = await UserFolderService.getFolders()
      dispatch(setFolders(folders))
    } catch (e) {
      SentryService.error("[ERROR UserFolderService.getFolders]:", e)
    }
  }, [])

  return (
    <Modal
      show={true}
      icon="shield"
      title="Cześć!"
      text="Zaloguj się do aplikacji"
      maxWidth="400px"
      showBackground={false}
    >
      <>
        {isLoginError && (
          <Alert type="error" small marginBottom="S">
            Nie udało się zalogować. Niewłaściwy email lub hasło.
          </Alert>
        )}
        <Input
          name="username"
          label="E-mail*"
          placeholder="imie.nazwisko@gmail.com"
          error={errors.username}
          rhf={register("username", {
            required: true,
            pattern: {
              value: EMAIL_REGEX,
              message: "Niepoprawny format email",
            },
          })}
          marginBottom="S"
        />
        <Input
          name="password"
          label="Hasło*"
          type="password"
          placeholder="min. 6 znaków"
          error={errors.password}
          rhf={register("password", {
            required: true,
          })}
          marginBottom="M"
        />
        <Button
          width="100%"
          marginBottom="XS"
          loading={isLoggingIn}
          onClick={handleSubmit(onSubmit)}
        >
          Zaloguj się
        </Button>
      </>
    </Modal>
  )
}

export default Login

const InitWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`
