import React, { useState } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { Label, P, Title } from "components/atoms/typography"
import { DocumentSignatureService } from "services/DocumentSignatureService"
import { DocumentSignatureType } from "services/types"
import { Box, Row } from "components/atoms/_atoms"
import Spinner from "components/atoms/spinner"
import Tag from "components/atoms/tag"
import { t } from "utils/translator"
import { formatDate } from "utils/date/date"
import { IconBox } from "components/atoms/icon"
import Button from "components/atoms/button"
import { useIsDocumentFavourite } from "store/folders"
import FavouriteModal from "components/modals/FavouriteModal"
import Link from "components/atoms/link"
import { useCustomQuery } from "hooks/useCustomQuery"
import { RootState } from "store/store"
import { AIService } from "services/AIService"
import QucikExplanationBlock from "components/shared/blocks/QucikExplanationBlock"

const DocumentSignatureFooter = ({ id }: { id: string }) => {
  const isFavourite = useIsDocumentFavourite(id)
  const [favouriteModal, setFavouriteModal] = useState(false)

  return (
    <>
      <FooterContent>
        <Button
          type="secondary"
          icon={isFavourite ? "heart-fill" : "heart"}
          color={isFavourite ? "primary" : "secondary"}
          onClick={() => setFavouriteModal(true)}
        >
          {isFavourite ? "Usuń z ulubionych" : "Dodaj do ulubionych"}
        </Button>
        <a
          href={DocumentSignatureService.generateFileUrl(id)}
          target="_blank"
          download
          rel="noreferrer"
        >
          <Button type="secondary" icon="save">
            Pobierz
          </Button>
        </a>
        <Link icon="full-screen" target="_blank" to={`/panel/documents/${id}`}>
          Pełny ekran
        </Link>
      </FooterContent>
      <FavouriteModal id={id} show={favouriteModal} setShow={setFavouriteModal} />
    </>
  )
}

const DocumentSignatureContent = ({ id }: { id: string }) => {
  const { questions } = useSelector((state: RootState) => state.search)

  const { isLoading, data } = useCustomQuery<DocumentSignatureType>({ key: ["document", id] }, () =>
    DocumentSignatureService.getById(id),
  )

  const { isLoading: isLoadingExplanation, data: explanation } = useCustomQuery<string>(
    { key: ["explanation", id, questions.join(" \n ")] },
    () => AIService.quickAnswer({ id, message: questions.join(" \n ") }),
  )

  if (isLoading || !data)
    return (
      <Row height="400px" justifyContent="center" alignItems="center">
        <Spinner />
      </Row>
    )

  return (
    <Wrapper>
      <Content>
        <TitleContent>
          <Box>
            <IconBox icon="cv" marginBottom="S" />
            <Title margin="0">{data?.name}</Title>
          </Box>
        </TitleContent>
        <InnerContent>
          <DocumentWrapper>
            <QucikExplanationBlock isLoading={isLoadingExplanation} marginBottom="S">
              {explanation}
            </QucikExplanationBlock>
            <DocumentContent
              className="custom-scroll-bar"
              dangerouslySetInnerHTML={{ __html: `${data?.content}` }}
            />
          </DocumentWrapper>
          <InfoContent>
            <TagsWrapper>
              {data?.date && (
                <Tag icon="clock" iconColor="primary" label={formatDate(data?.date)} />
              )}
              <Tag icon="decision" iconColor="primary" label={t(data?.judgmentType)} />
              <Tag icon="court" iconColor="primary" label={t(data?.courtType)} />
            </TagsWrapper>
            {data?.division && (
              <DivisionWrapper>
                <Label type="third" marginBottom="S">
                  Wydział orzekający
                </Label>
                <P marginBottom="XS">{data?.division?.name}</P>
                <Tag type="secondary" background="backgroundDarker" label={data?.division?.type} />
              </DivisionWrapper>
            )}
            {data?.division?.court && (
              <DivisionWrapper>
                <Label type="third" marginBottom="S">
                  Sąd orzekający
                </Label>
                <P marginBottom="XS">{data?.division?.court?.name}</P>
                <Tag
                  type="secondary"
                  background="backgroundDarker"
                  label={t(data?.division?.court.type)}
                />
              </DivisionWrapper>
            )}

            {data?.judges && data?.judges.length > 0 && (
              <P label="Sędziowie" margin="0">
                {data?.judges?.map(el => el.name)?.join(", ")}
              </P>
            )}
            {data?.legalBases && data?.legalBases.length > 0 && (
              <P label="Podstawy prawne orzeczenia" margin="0">
                {data?.legalBases?.join(", ")}
              </P>
            )}
            {data?.keywords && data?.keywords.length > 0 && (
              <P label="Słowa kluczowe" margin="0">
                {data?.keywords}
              </P>
            )}
            <P label="Zródło" margin="0">
              {t(data?.externalId?.source)}
            </P>
          </InfoContent>
        </InnerContent>
      </Content>
      <DocumentSignatureFooter id={data.id} />
    </Wrapper>
  )
}

export default DocumentSignatureContent

const DocumentWrapper = styled(Box)`
  flex: 1;
`

const DocumentContent = styled(Box)`
  padding: ${({ theme }) => theme.space.S};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  background: ${({ theme }) => theme.color.background};
  overflow: scroll;
  overflow-x: hidden;
  flex: 1;

  * {
    font-family: auto !important;
    white-space: normal !important;
  }
`

const TagsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const TitleContent = styled(Box)`
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DivisionWrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.S};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  background: ${({ theme }) => theme.color.background};
`

const InfoContent = styled(Box)`
  width: 30%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
  height: max-content;
  overflow: scroll;
`

const FooterContent = styled(Box)`
  width: 100%;
  background: white;
  padding: ${({ theme }) => theme.space.S};
  display: flex;
  justify-content: flex-end;
  grid-gap: ${({ theme }) => theme.space.S};
  box-shadow: ${({ theme }) => theme.variable.boxShadow};
  position: sticky;
  bottom: 0;
`

const InnerContent = styled(Box)`
  flex: 1 1;
  display: flex;
  grid-gap: ${({ theme }) => theme.space.S};
  overflow: scroll;
`

const Wrapper = styled(Box)`
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  position: relative;
`

const Content = styled(Box)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;
  padding: ${({ theme }) => theme.space.S};
`
