import { APIService } from "./APIService"
import { ShortDocumentSignatureType, UserFolderType } from "./types"

const getFolders = () => APIService.get<null, UserFolderType[]>(`/users/:userId/folders`)
const addFolder = (name: string) =>
  APIService.post<null, UserFolderType>(`/users/:userId/folders?name=${name}`)
const editFolder = (id: string, name: string) =>
  APIService.patch<null, UserFolderType>(`/users/:userId/folders/${id}?name=${name}`)
const deleteFolder = (id: string) => APIService.delete<null, null>(`/users/:userId/folders/${id}`)
const addToFolder = (folderId: string, documentId: string) =>
  APIService.post<null, null>(`/users/:userId/folders/${folderId}/documents?id=${documentId}`)
const deleteToFolder = (folderId: string, documentId: string) =>
  APIService.delete<null, null>(`/users/:userId/folders/${folderId}/documents?id=${documentId}`)
const getFolderDocuments = (id: string) =>
  APIService.get<null, ShortDocumentSignatureType[]>(`/users/:userId/folders/${id}/documents`)

export const UserFolderService = {
  getFolders,
  addFolder,
  editFolder,
  deleteFolder,
  addToFolder,
  deleteToFolder,
  getFolderDocuments,
}
