import React from "react"
import { Outlet, useNavigate } from "react-router-dom"
import PanelContentLayout from "components/layout/PanelContentLayout"
// import FolderFavouritesView from "views/Panel/Favourites/FolderFavouritesView"

const Profile = () => {
  const navigate = useNavigate()

  return (
    <>
      <PanelContentLayout
        icon="heart"
        title="Ulubione dokumenty"
        text="Lista Twoich ulubionych dokumentów"
      >
        <Outlet />
      </PanelContentLayout>
    </>
  )
}

export default Profile
