import React, { useState } from "react"
import styled from "styled-components"
import { ChatRow } from "components/shared/Documents/components/DocumentChat"
import { DocumentSignatureType } from "services/types"
import { Box } from "components/atoms/_atoms"
import SignatureContentInfoPrimary from "components/shared/Documents/Signature/components/SignatureContentInfoPrimary"

const SignatureContentInfo = ({
  data,
  showChat = true,
  setShowChat,
}: {
  data: DocumentSignatureType
  showChat: boolean
  setShowChat: (e: boolean) => void
}) => {
  const [accordion, setAccordion] = useState<null | string>("basic")

  const handleShowAccordion = (value: boolean, name: string) => {
    if (!value) {
      setAccordion(null)
    } else {
      setAccordion(name)
    }
  }

  return (
    <Wrapper showChat={showChat} className="hide_scroll">
      {!showChat && <ChatRow setShow={setShowChat} />}
      <SignatureContentInfoPrimary
        data={data}
        show={accordion === "basic"}
        setShow={e => handleShowAccordion(e, "basic")}
      />
    </Wrapper>
  )
}

export default SignatureContentInfo

const Wrapper = styled(Box)<{
  showChat: boolean
}>`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.S};
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  padding-bottom: ${({ theme }) => theme.space.S};
`
