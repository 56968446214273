import React from "react"
import { Outlet, useNavigate } from "react-router-dom"
import styled from "styled-components"
import PanelContentLayout from "components/layout/PanelContentLayout"
import { Box } from "components/atoms/_atoms"
// import FolderFavouritesView from "views/Panel/Favourites/FolderFavouritesView"

const History = () => {
  const navigate = useNavigate()

  return (
    <>
      <PanelContentLayout
        icon="clock"
        title="Historia wyszukiwań"
        text="Twoja lista wyszukiwań w aplikacji"
      >
        <Outlet />
      </PanelContentLayout>
    </>
  )
}

export default History

const Content = styled(Box)`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: ${({ theme }) => theme.space.XS};
`
