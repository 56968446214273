import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { MessageType } from "components/molecules/chat/types"
import { makeId } from "utils/id"
import { OptionType } from "components/atoms/input/InputAutocomplete"

export interface ChatFilterType {
  signatures: boolean
  signaturesIds: OptionType[]
  acts: boolean
  actsIds: OptionType[]
}

export interface ChatType {
  id: string
  isDraft?: boolean
  messages: MessageType[]
  filters: ChatFilterType | null
  date: Date
}

interface ChatsState {
  chats: ChatType[]
}

export const defaultMessage: MessageType = {
  id: "0",
  type: "ai",
  message: "Cześć! Jak mogę Ci pomóc?",
}

const initialState: ChatsState = {
  chats: [
    {
      id: makeId(5),
      isDraft: true,
      messages: [defaultMessage],
      date: new Date(),
      filters: null,
    },
  ],
}

export const chatsSlice = createSlice({
  name: "chats",
  initialState: initialState,
  reducers: {
    addChat: (state, action: PayloadAction<ChatType>) => {
      const chats = state.chats || []
      chats.push(action.payload)

      state = {
        chats: chats,
      }
    },
    updateChat: (state, action: PayloadAction<Omit<ChatType, "title" | "date">>) => {
      const chats = state.chats || []

      const index = chats.findIndex(el => el.id === action.payload.id)
      const chat = chats[index]

      chats[index] = {
        id: chat.id,
        messages: action.payload.messages,
        filters: action.payload.filters,
        date: new Date(),
      }

      state = {
        chats,
      }
    },
  },
})

export const { addChat, updateChat } = chatsSlice.actions

export default chatsSlice.reducer
