import React from "react"
import { useParams, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import DocumentContent from "components/shared/Documents/components/DocumentContent"

const DocumentView = ({ showGoBack = true }: { showGoBack?: boolean }) => {
  const { id = "" } = useParams()
  const [searchParams] = useSearchParams()
  const q = searchParams.get("q") || ""

  return (
    <Wrapper>
      <DocumentContent id={id} q={q} showGoBack={showGoBack} showTitle={false} />
    </Wrapper>
  )
}

export default DocumentView

const Wrapper = styled.div`
  height: calc(100svh - ${({ theme }) => theme.space.M} - ${({ theme }) => theme.space.S} - 32px);
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
`
