import { APIService } from "./APIService"
import { UserType } from "./types"

const getProfiles = (id: string) => APIService.get<null, string[]>(`/users/${id}/profiles`)

const updateUser = (body: { firstName: string; lastName: string }) =>
  APIService.patch<null, UserType>(`/users/:userId`, body)

const setPassword = (password: string) =>
    APIService.put<null, UserType>(`/users/:userId/password?password=${password}`)

const updatePassword = (oldPassword: string, newPassword: string) =>
    APIService.patch<null, UserType>(`/users/:userId/password?oldPassword=${oldPassword}&newPassword=${newPassword}`)


export const UserService = {
  getProfiles,
  updateUser,
  setPassword,
  updatePassword,
}
