import React, { createContext, useContext, useEffect, useState } from "react"
import { LocalStorageService } from "services"

export const FavouritesContext = createContext<{
  signaturesIds: string[]
  toggleFavourites: (e: string) => void
}>({
  signaturesIds: [],
  toggleFavourites: () => {},
})

export const FavouritesContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(true)
  const [signaturesIds, setSignaturesIds] = useState<string[]>([])

  const toggleFavourites = (id: string) => {
    let newIds: string[] = []
    if (!signaturesIds.includes(id)) {
      newIds = [...signaturesIds, id]
    } else {
      newIds = signaturesIds.filter(el => el !== id)
    }

    setSignaturesIds(newIds)
    LocalStorageService.setObject("favourites_signatures", JSON.stringify(newIds))
  }

  useEffect(() => {
    const fetchIds = async () => {
      const storageSignaturesIds = LocalStorageService.getObject("favourites_signatures")
      if (storageSignaturesIds) {
        setSignaturesIds(JSON.parse(storageSignaturesIds))
      }
    }

    fetchIds()
  }, [])

  return (
    <FavouritesContext.Provider value={{ signaturesIds, toggleFavourites }}>
      {children}
    </FavouritesContext.Provider>
  )
}

export const useFavourites = (id?: string) => {
  const { signaturesIds, toggleFavourites } = useContext(FavouritesContext)

  return {
    ids: signaturesIds,
    isFavourite: signaturesIds.includes(`${id}`),
    toggleFavourites,
  }
}
