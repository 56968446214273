import React, { ReactNode } from "react";
import styled, { css } from "styled-components"
import { common } from "components/atoms/_atoms"
import { BaseStyles } from "types/base-styles";

export type { LabelProps } from "./Label";
export { Label } from "./Label"
export { pStyles, P } from "./P"

export type { TitleProps } from "./Title";
export { Title } from "./Title"

const styleType = css<{
  styleType: string
}>`
  ${({ styleType }) =>
      styleType === "primary" &&
      css`
      ${h1Styles};
    `}

  ${({ styleType }) =>
      styleType === "secondary" &&
      css`
      ${h2Styles};
    `}

  ${({ styleType }) =>
      styleType === "third" &&
      css`
      ${h3Styles};
    `}

  ${({ styleType }) =>
      styleType === "fourth" &&
      css`
      ${h4Styles};
    `}
`

export const Span = styled.span<BaseStyles>`
  color: ${({ theme, color = "primary" }) => theme.color[color] ?? color};
  font-weight: ${({ theme, fontWeight = "inherit" }) => theme.font[fontWeight]};
`

export const h1Styles = css<BaseStyles>`
  font-family: 'Manrope' !important;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.space.L};
  ${common};
  font-size: ${({ theme, fontSize = "XXL" }) => theme.font[fontSize]};
  font-weight: ${({ theme, fontWeight = "bold" }) => theme.font[fontWeight]};
  line-height: normal;
  color: ${({ theme, color = "second" }) => theme.color[color]};
`

const StyledH1 = styled.h1<BaseStyles & {
  className?: string
  styleType: string
}>`
  ${styleType};
`

export const H1 = ({ styleType = "primary", children, ...rest } : BaseStyles & {
  className?: string
  styleType?: string
  children: ReactNode
}) => {
  return <StyledH1 styleType={styleType} {...rest}>{children}</StyledH1>
}

export const StyledLabelH1 = styled.label<BaseStyles & {
  className?: string
  styleType: string
}>`
  ${h1Styles};
`
export const LabelH1 = ({ styleType = "primary", children, ...rest } : BaseStyles & {
  className?: string
  styleType?: string
  children: ReactNode
}) => {
  return <StyledLabelH1 styleType={styleType} {...rest}>{children}</StyledLabelH1>
}

export const h2Styles = css<BaseStyles>`
  font-family: 'Manrope' !important;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.space.M};
  ${common};
  font-size: ${({ theme, fontSize = "XXL" }) => theme.font[fontSize]};
  font-weight: ${({ theme }) => theme.font.bold};
  line-height: normal;
  color: ${({ theme, color = "second" }) => theme.color[color]};
  display: flex;
  justify-content: ${({ textAlign = "flex-start" }) => textAlign};
  align-items: flex-end;

  span {
    font-size: ${({ theme }) => theme.font.L};
    line-height: ${({ theme }) => theme.font.L};
    color: ${({ theme }) => theme.color.third};
    font-weight: ${({ theme }) => theme.font.normal};
    margin-left: 8px;
  }
`

export const StyledH2 = styled.h2<BaseStyles & {
  className?: string
  styleType: string
}>`
  ${styleType};
`

export const H2 = ({ styleType = "secondary", children, ...rest } : BaseStyles & {
  className?: string
  styleType?: string
  children: ReactNode
}) => {
  return <StyledH2 styleType={styleType} {...rest}>{children}</StyledH2>
}

export const StyledLabelH2 = styled.label<BaseStyles & {
  className?: string
  styleType: string
}>`
  ${h2Styles};
`
export const LabelH2 = ({ styleType = "primary", children, ...rest } : BaseStyles & {
  className?: string
  styleType?: string
  children: ReactNode
}) => {
  return <StyledLabelH2 styleType={styleType} {...rest}>{children}</StyledLabelH2>
}

export const h3Styles = css<BaseStyles>`
  font-family: 'Manrope' !important;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.space.S};
  ${common};
  font-size: ${({ theme, fontSize = "XL" }) => theme.font[fontSize]};
  font-weight: ${({ theme }) => theme.font.normal};
  line-height: normal;
  color: ${({ theme, color = "second" }) => theme.color[color]};
`

export const StyledH3 = styled.h2<BaseStyles & {
  className?: string
  styleType: string
}>`
  ${styleType};
`


export const H3 = ({ styleType = "third", children, ...rest } : BaseStyles & {
  className?: string
  styleType?: string
  children: ReactNode
}) => {
  return <StyledH3 styleType={styleType} {...rest}>{children}</StyledH3>
}

export const StyledLabelH3 = styled.label<BaseStyles & {
  className?: string
  styleType: string
}>`
  ${h3Styles};
`
export const LabelH3 = ({ styleType = "primary", children, ...rest } : BaseStyles & {
  className?: string
  styleType?: string
  children: ReactNode
}) => {
  return <StyledLabelH3 styleType={styleType} {...rest}>{children}</StyledLabelH3>
}

export const h4Styles = css<BaseStyles>`
  font-family: 'Manrope' !important;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.space.XS};
  ${common};
  font-size: ${({ theme, fontSize = "L" }) => theme.font[fontSize]};
  font-weight: ${({ theme }) => theme.font.normal};
  line-height: normal;
  color: ${({ theme, color = "second" }) => theme.color[color]};
`

export const StyledH4 = styled.h4<BaseStyles & {
  className?: string
  styleType: string
}>`
  ${styleType};
`

export const H4 = ({ styleType = "fourth", children, ...rest } : BaseStyles & {
  className?: string
  styleType?: string
  children: ReactNode
}) => {
  return <StyledH4 styleType={styleType} {...rest}>{children}</StyledH4>
}

export const StyledLabelH4 = styled.label<BaseStyles & {
  className?: string
  styleType: string
}>`
  ${h4Styles};
`
export const LabelH4 = ({ styleType = "primary", children, ...rest } : BaseStyles & {
  className?: string
  styleType?: string
  children: ReactNode
}) => {
  return <StyledLabelH4 styleType={styleType} {...rest}>{children}</StyledLabelH4>
}

