import React from "react"
import "./App.css"
import { Provider } from "react-redux"
import * as Sentry from "@sentry/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import Home from "./Home"
import { store } from "store/store"
import { StyledComponentsProvider } from "providers/StyledComponentsProvider"
import { FavouritesContextProvider } from "contexts/FavouritesContext"

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https:api.parrotsearch.ai/api/"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <StyledComponentsProvider>
          <FavouritesContextProvider>
            <Home />
          </FavouritesContextProvider>
        </StyledComponentsProvider>
      </Provider>
    </QueryClientProvider>
  )
}

export default App
