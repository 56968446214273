import React from "react"
import styled, { css } from "styled-components"
import usePagination from "@mui/material/usePagination"
import Icon from "components/atoms/icon";

export const Pagination = ({
  defaultPage,
  totalPages,
  onChange,
}: {
  defaultPage?: number
  totalPages: number
  onChange: (e: number) => void
}) => {
  const { items } = usePagination({
    defaultPage: defaultPage,
    count: totalPages,
    onChange: (e, page) => {
      onChange(page)
    }
  })

  return (
    <PaginationWrapper>
      {items.map(({ page, type, selected, ...item }, index) => {
        let children = null

        if (type === "start-ellipsis" || type === "end-ellipsis") {
          children = "…"
        } else if (type === "page") {
          children = (
            <PaginationNumber
              key={index}
              active={selected}
              {...item}
            >
              {page}
            </PaginationNumber>
          )
        } else {
          children = (
            <PaginationNumber
              arrow
              {...item}
            >
              <Icon icon={type === "next" ? "arrow-right" : "arrow-left"} />
            </PaginationNumber>
          )
        }

        return children
      })}
    </PaginationWrapper>
  )
}

export default Pagination

const PaginationNumber = styled.button<{
  arrow?: boolean
  active?: boolean
}>`
  color: ${({ theme }) => theme.color.second};
  font-size: ${({ theme }) => theme.font.M};
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  border-radius: 3px;

  svg {
    width: ${({ theme }) => theme.font.M};
  }

  &:hover{
    color: ${({ theme }) => theme.color.primary};
  }
  
  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.color.primary} !important;
      color: white !important;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
      cursor: not-allowed;
    `}

  ${({ arrow }) =>
    arrow &&
    css`
      font-size: 10px;
    `}
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: ${({ theme }) => theme.space.M};
  margin-bottom: ${({ theme }) => theme.space.M};
`
