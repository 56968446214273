import { APIService } from "./APIService"
import { AddProfileCompanyType, EditProfileType, Page, ProfileType, UserType } from "./types"

const getProfile = (id: string) => APIService.get<null, ProfileType>(`/profiles/${id}`)

// UPDATE
const update = (profile: EditProfileType) =>
  APIService.patch<null, ProfileType>(`/profiles/:profileId`, profile)
const updateCompany = (company: AddProfileCompanyType) =>
  APIService.patch<null, ProfileType>(`/profiles/:profileId/company`, company)
const getUsers = (page: number) =>
  APIService.get<null, Page<UserType>>(`/profiles/:profileId/users?page=${page}`)

const addUser = (body: object) =>
    APIService.post<null, null>(`/profiles/:profileId/users`, body)

const deleteUser = (id: string) =>
    APIService.delete<null, null>(`/profiles/:profileId/users/${id}`)

const resendUserActivation = (id: string) =>
    APIService.post<null, null>(`/profiles/:profileId/users/${id}/resend-activation`)

export const ProfileService = {
  getProfile,
  update,
  updateCompany,
  resendUserActivation,
  getUsers,
  addUser,
  deleteUser,
}
