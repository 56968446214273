import React from "react"
import styled from "styled-components"
import { Outlet } from "react-router-dom"
import PanelNavigationLayout from "./PanelNavigationLayout"

const PanelLayout = ({ children }: any) => {

  return (
    <Wrapper>
      <PanelNavigationLayout />
      <Content>
          <Outlet />
      </Content>
    </Wrapper>
  )
}

export default PanelLayout

const Content = styled.div`
  flex: 1 1;
  height: 100vh;
  overflow: scroll;
  background: ${({ theme }) => theme.color.background};
`

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
`
