import axios from "axios"
import { da } from "date-fns/locale"

export const API_URL = process.env.REACT_APP_API_AI_URL

interface Root<T> {
  data: T
}

export interface AISearchResponse {
  questions: string[]
  answer: string
  results: AISourceDocument[]
}

export interface AISourceDocument {
  id: string
  score: number
  content: string
  metadata: Metadata
  summary: string
  summary_code: string
}

interface Metadata {
  id: string
  title: string
  type: string
  titleUpper: string
  courtType: string
  courtTypeStr: string
  judgmentType: string
  judgmentTypeStr: string
  date: string
  datetime: string
  divisionId: string
  divisionCategory: string
  divisionCode: string
  divisionType: string
  divisionName: string
  divisionCourtId: string
  divisionCourtCode: string
  divisionCourtName: string
  divisionCourtType: string
  divisionChamberId: string
  divisionChamberName: string
  judges: string[]
  sourceId: string
  sourceName: string
  keywords: string[]
  legalBases: string[]
}

const search = async (str: string[], filters?: any): Promise<AISearchResponse> => {
  const { data } = await axios.post<null, Root<AISearchResponse>>(`${API_URL}/search`, {
    q: str,
  })

  const results = data.results
    .filter(el => el.metadata.type === "text")
    .sort((a, b) => {
      const indexA = ["success", "mixed", "error"].indexOf(a.summary_code)
      const indexB = ["success", "mixed", "error"].indexOf(b.summary_code)
      return indexA - indexB
    })

  return {
    questions: data.questions,
    results: results,
    answer: data.answer,
  }
}

const chat = async ({
  message,
  history = [],
  types,
  ids,
  id,
}: {
  message: string
  history?: string[][]
  types?: string[]
  ids?: string[]
  id?: string
}): Promise<{
  answer: string
  question: string
  source_documents: [
    {
      page_content: string
      metadata: {
        id: string
        title: string
        type: string
      }
    },
  ]
}> => {
  const { data } = await axios.post<null, any>(`${API_URL}/chat`, {
    message,
    history,
    types,
    id,
    ids,
  })

  return data
}

const quickAnswer = async ({ id, message }: { id: string; message: string }): Promise<string> => {
  const { data } = await axios.post<
    null,
    Root<{
      answer: string
      source_documents: {
        page_content: string
      }[]
    }>
  >(`${API_URL}/quick-answer`, {
    id,
    message,
  })

  console.log(`~`, data)
  return data.answer
}

export const AIService = {
  search,
  chat,
  quickAnswer,
}
