import React from "react"
import Modal from "components/molecules/modal"
import { useCustomQuery } from "hooks/useCustomQuery"
import { AIService } from "services/AIService"
import QucikExplanationBlock from "components/shared/blocks/QucikExplanationBlock"

export const DocumentSearchSimilarModal = ({
  q = "",
  show,
  id,
  setShow,
}: {
  q?: string
  id: string
  show: boolean
  setShow: (e: boolean) => void
}) => {
  const isValidQ = !!q && q !== ""
  const { isLoading: isLoadingExplanation, data: explanation } = useCustomQuery<string>(
    { key: ["explanation", q] },
    () => AIService.quickAnswer({ id: id, message: q }),
    isValidQ,
  )

  return (
    <Modal icon="search" title="Wyjaśnij zaznaczony tekst" show={show} setShow={setShow}>
      <>
        {isValidQ && (
          <QucikExplanationBlock isOpen isLoading={isLoadingExplanation} marginBottom="S">
            {explanation}
          </QucikExplanationBlock>
        )}
      </>
    </Modal>
  )
}

export default DocumentSearchSimilarModal
