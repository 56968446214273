import React, { useState } from "react"
import styled from "styled-components"
import { Box } from "components/atoms/_atoms"
import { BaseStyles } from "types/base-styles"
import Icon from "components/atoms/icon"
import { Label } from "components/atoms/typography"
import P from "components/atoms/typography/P"
import { MessageLoading } from "components/molecules/chat/ChatMessage"

const QucikExplanationBlock = ({
  children,
  isLoading,
  isOpen = false,
  ...rest
}: {
  isLoading: boolean
  children?: string
  isOpen?: boolean
} & BaseStyles) => {
  const [fullSummary, setFullSummary] = useState(isOpen)

  return (
    <SummaryWrapper {...rest}>
      <Icon icon="lightbulb" size="L" color="primary" />
      <Box>
        <Label color="primary" fontSize="XS" marginBottom="XS" fontWeight="bold">
          Szybkie wyjaśnienie
        </Label>
        {isLoading && <MessageLoading color="primary" small />}
        {!isLoading && children && (
          <P fontSize="S" margin="0" color="primary">
            {fullSummary ? children : `${children.substring(0, 180)}...`}
            {fullSummary ? (
              <>
                <br />
                <br />
                <span onClick={() => setFullSummary(!fullSummary)}>Zobacz mniej</span>
              </>
            ) : (
              <span onClick={() => setFullSummary(!fullSummary)}> Zobacz więcej</span>
            )}
          </P>
        )}
      </Box>
    </SummaryWrapper>
  )
}

export default QucikExplanationBlock

const SummaryWrapper = styled(Box)<{
  suggested?: boolean
}>`
  display: flex;
  grid-gap: ${({ theme }) => theme.space.XS};
  padding: ${({ theme }) => theme.space.XS};
  padding-right: ${({ theme }) => theme.space.S};
  background: ${({ theme }) => theme.color.primaryBackground};
  border-radius: ${({ theme }) => theme.variable.borderRadius};

  span {
    cursor: pointer;
  }
`
