import React, { useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import Modal from "components/molecules/modal"
import { splitIds } from "utils/id"
import DocumentTabs from "components/shared/Documents/components/DocumentTabs"
import DocumentContent from "components/shared/Documents/components/DocumentContent"

const DocumentsWithTabsView = ({
  url,
  enabledExplanation = true,
}: {
  url?: string
  enabledExplanation?: boolean
}) => {
  const navigate = useNavigate()
  const { ids: rawIds = "" } = useParams()
  const [searchParams] = useSearchParams()
  const index = searchParams.get("index") || 0
  const q = searchParams.get("q") || ""

  const ids = splitIds(rawIds)

  const [id, setId] = useState(ids[Number(index)])

  return (
    <Modal
      show={true}
      setShow={() => (url ? navigate(url) : navigate(-1))}
      type="side"
      maxWidth="98%"
      contentProps={{ padding: 0 }}
      closeBackground="white"
    >
      <Wrapper>
        <DocumentTabs ids={ids} id={id} setId={setId} />
        <DocumentContent id={id} q={q} />
      </Wrapper>
    </Modal>
  )
}

export default DocumentsWithTabsView

const Wrapper = styled.div`
  height: 100svh;
  display: flex;
  flex-direction: column;
  position: relative;
`
