import React from "react"
import styled, { css } from "styled-components"
import { Box } from "components/atoms/_atoms"
import { Label } from "components/atoms/typography"
import { IconButton } from "components/atoms/button"
import { useCustomQuery } from "hooks/useCustomQuery"
import { DocumentSignatureType } from "services/types"
import { DocumentSignatureService } from "services/DocumentSignatureService"

const Tab = ({
  id,
  currentId,
  setId,
}: {
  id: string
  currentId?: string
  setId: (e: string) => void
}) => {
  const { isLoading, data } = useCustomQuery<DocumentSignatureType>({ key: ["document", id] }, () =>
    DocumentSignatureService.getById(id),
  )

  return (
    <TabWrapper active={id === currentId} onClick={() => setId(id)}>
      <Label type="secondary">{isLoading ? "Loading ..." : data?.name}</Label>
      {/*<TabCloseButton*/}
      {/*  borderRadius="15px"*/}
      {/*  size="15px"*/}
      {/*  iconSize="9px"*/}
      {/*  icon="close"*/}
      {/*  color="third"*/}
      {/*  background="none"*/}
      {/*  onClick={() => {}}*/}
      {/*/>*/}
    </TabWrapper>
  )
}

const DocumentTabs = ({
  ids,
  id,
  setId,
}: {
  ids: string[]
  id: string
  setId: (e: string) => void
}) => {
  return (
    <>
      <Wrapper className="hide_scroll">
        {ids.map(el => (
          <Tab key={el} id={el} currentId={id} setId={setId} />
        ))}
      </Wrapper>
    </>
  )
}

export default DocumentTabs

const TabCloseButton = styled(IconButton)`
  transform: translateX(50%);
  opacity: 0;
  // position: absolute !important;
  // right: calc(${({ theme }) => theme.space.M} / 2);
`

const TabWrapper = styled.button<{
  active?: boolean
}>`
  min-width: max-content;
  display: flex;
  align-items: center;
  // grid-gap: ${({ theme }) => theme.space.XS};
  padding: ${({ theme }) => theme.space.XS} ${({ theme }) => theme.space.M};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  border: none;
  background: none;
  position: relative;
  cursor: context-menu;
  position: relative;

  &:hover {
    background: ${({ theme }) => theme.color.backgroundDarker};

    button {
      opacity: 1;
    }
  }

  * {
    position: relative;
  }

  label {
    color: ${({ theme }) => theme.color.third};
  }

  ${({ active }) =>
    active &&
    css`
      background: white !important;
      box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.05);

      label {
        color: ${({ theme }) => theme.color.second};
      }

      button {
        opacity: 1;
      }

      &:before {
        position: absolute;
        background: white !important;
        border-radius: ${({ theme }) => theme.variable.borderRadius}
          ${({ theme }) => theme.variable.borderRadius} 0 0;
        display: block;
        content: "";
        width: 100%;
        height: calc(100% + ${({ theme }) => theme.space.XXS});
        left: 0;
        top: 0;
      }
    `}
`

const Wrapper = styled(Box)`
  background: ${({ theme }) => theme.color.backgroundDarker};
  padding-top: ${({ theme }) => theme.space.XS};
  padding-bottom: 0;
  padding-left: ${({ theme }) => theme.space.XXS};
  padding-right: ${({ theme }) => theme.space.XL};
  grid-gap: ${({ theme }) => theme.space.XXS};
  display: flex;
  width: 100%;
  overflow: scroll;
`
