import React from "react"
import styled from "styled-components"
import { Box, Row } from "components/atoms/_atoms"
import { useCustomQuery } from "hooks/useCustomQuery"
import { DocumentSignatureType } from "services/types"
import { DocumentSignatureService } from "services/DocumentSignatureService"
import Spinner from "components/atoms/spinner"
import Signature from "components/shared/Documents/Signature/Signature"

const DocumentContent = ({
  id,
  q,
  showGoBack = true,
  showTitle = true,
}: {
  id: string
  q: string
  showGoBack?: boolean
  showTitle?: boolean
}) => {
  const { isLoading, data } = useCustomQuery<DocumentSignatureType>({ key: ["document", id] }, () =>
    DocumentSignatureService.getById(id),
  )

  if (isLoading || !data) {
    return (
      <Row justifyContent="center" width="100%" height="100%">
        <Spinner />
      </Row>
    )
  }

  return (
    <Wrapper>
      <Signature showGoBack={showGoBack} showTitle={showTitle} data={data} q={q} />
    </Wrapper>
  )
}

export default DocumentContent

const Wrapper = styled(Box)`
  height: 0;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  position: relative;
`
