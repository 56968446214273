import React, { ReactElement, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { CSSTransition } from "react-transition-group"
import { createPortal } from "react-dom"
import { P, Title } from "components/atoms/typography"
import Button, { IconButton } from "components/atoms/button"
import { Absolute, Box } from "components/atoms/_atoms"
import { IconBox } from "components/atoms/icon"

const Portal = ({ id, children }: { id: string; children: ReactElement }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  // @ts-ignore
  return mounted ? createPortal(children, document.querySelector(`#${id}`)) : null
}

const Modal = ({
  children,
  show,
  setShow,
  type = "center",
  maxWidth = "500px",
  maxHeight = "none",
  icon,
  label,
  title,
  text,
  bottomElement,
  helperElement,
  helperButton,
  contentProps,
  background,
  closeBackground = "backgroundDarker",
  showBackground = true,
  mobileFullScreen = false,
  modalBackgroundId = "root-modal-content",
  modalContentId = "root-modal-content",
}: {
  children: ReactElement
  show: boolean
  setShow?: (e: boolean) => void
  type?: "center" | "side"
  maxWidth?: string
  maxHeight?: string
  icon?: string
  label?: string
  title?: string
  text?: string
  background?: string
  closeBackground?: string
  contentProps?: object
  helperElement?: ReactElement
  bottomElement?: ReactElement
  helperButton?: {
    text: string
    color?: string
    onClick: () => void
  }
  showBackground?: boolean
  mobileFullScreen?: boolean
  modalBackgroundId?: string
  modalContentId?: string
}) => {
  let childrenElement
  if (children) {
    if (Array.isArray(children)) {
      childrenElement = []
      children.map(el => childrenElement.push(React.cloneElement(el, { setShow: setShow })))
    } else {
      childrenElement = React.cloneElement(children, { setShow: setShow })
    }
  }

  return (
    <>
      {showBackground && (
        <Portal id={modalBackgroundId}>
          <CSSTransition in={show} timeout={200} classNames="background" unmountOnExit>
            <Background onClick={setShow ? () => setShow(false) : () => {}} />
          </CSSTransition>
        </Portal>
      )}
      <Portal id={modalContentId}>
        <CSSTransition in={show} timeout={200} classNames="content" unmountOnExit>
          <>
            <Wrapper
              type={type}
              mobileFullScreen={mobileFullScreen}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
              className={`hide_scroll`}
            >
              <InnerWrapper type={type} background={background} className={`sidebar_block hide_scroll`}>
                {title && (
                  <TitleContent>
                    <Box>
                      {icon && <IconBox icon={icon} marginBottom="S" />}
                      <Title label={label} margin="0">
                        {title}
                      </Title>
                      {text && (
                        <P small margin="0" marginTop="XS">
                          {text}
                        </P>
                      )}
                    </Box>
                  </TitleContent>
                )}
                <Content {...contentProps}>{childrenElement}</Content>
                {/*{!title && setShow && (*/}
                {/*  <AbsoluteClose>*/}
                {/*    <IconButton icon="close" onClick={() => setShow(false)} />*/}
                {/*  </AbsoluteClose>*/}
                {/*)}*/}
                {bottomElement && (
                  <BottomElementWrapper type={type}>{bottomElement}</BottomElementWrapper>
                )}
                {setShow && (
                  <Absolute right="10px" top="10px">
                    <IconButton icon="close" background={closeBackground} onClick={() => setShow(false)} />
                  </Absolute>
                )}
              </InnerWrapper>
              {helperElement && <HelperWrapper>{helperElement}</HelperWrapper>}
              {helperButton && (
                <Button
                  type="third"
                  color={helperButton.color ?? "white"}
                  margin="0 auto"
                  onClick={helperButton.onClick}
                >
                  {helperButton.text}
                </Button>
              )}
            </Wrapper>
          </>
        </CSSTransition>
      </Portal>
    </>
  )
}

export default Modal

const AbsoluteClose = styled(Box)`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
`

const BottomElementWrapper = styled(Box)<{
  contentPadding?: string
  type?: "center" | "side"
}>`
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: ${({ theme, contentPadding }) => contentPadding ?? theme.space.S};

  ${({ type }) =>
    type === "center" &&
    css`
      background: white;
      box-shadow: ${({ theme }) => theme.variable.boxShadow};
    `}

  ${({ type }) => type === "side" && css``}
`

const HelperWrapper = styled(Box)`
  width: 100%;
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  background: ${({ theme }) => theme.color.background};
  padding: ${({ theme }) => theme.space.S};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.space.S};
`

const Content = styled(Box)`
  padding: ${({ theme, padding }) => padding ?? theme.space.S};
  white-space: break-spaces;
`

const TitleContent = styled(Box)`
  padding: ${({ theme }) => theme.space.S} ${({ theme }) => theme.space.S};
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const InnerWrapper = styled(Box)<{
  type?: "center" | "side"
}>`
  background: ${({ theme, background = "white" }) => theme.color[background] ?? background};
  padding: 0px;
  box-shadow: ${({ theme }) => theme.variable.boxShadow};
  overflow: scroll;
  position: relative;
  height: 100%;

  ${({ type }) =>
    type === "center" &&
    css`
      border-radius: ${({ theme }) => theme.variable.borderRadius};
    `}
`

const Wrapper = styled.div<{
  type?: "center" | "side"
  mobileFullScreen?: boolean
  maxWidth?: string
  maxHeight?: string
}>`
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
  opacity: 1;
  z-index: 99;
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  ${({ type, mobileFullScreen }) =>
    type === "center" &&
    css`
      max-height: 90%;
      width: 90% !important;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &.content-enter {
        opacity: 0;
        transform: translate(-50%, calc(-50% + 30px));
      }

      &.content-enter-active {
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: opacity 200ms, transform 0.2s;
      }

      &.content-exit {
        opacity: 1;
        transform: translate(-50%, -50%);
      }

      &.content-exit-active {
        opacity: 0;
        transform: translate(-50%, calc(-50% + 30px));
        transition: opacity 200ms, transform 0.2s;
      }

      ${mobileFullScreen &&
      css`
        @media (max-width: 700px) {
          width: 100% !important;
          max-height: 100%;
          max-width: none;
        }
      `};
    `};

  ${({ type }) =>
    type === "side" &&
    css`
      width: 100% !important;
      height: 100%;
      right: 0;
      top: 0;
      transform: translate(0, 0);

      &.content-enter {
        transform: translate(100%, 0);
      }

      &.content-enter-active {
        transform: translate(0, 0);
        transition: opacity 200ms, transform 0.2s;
      }

      &.content-exit {
        transform: translate(0, 0);
      }

      &.content-exit-active {
        transform: translate(100%, 0);
        transition: opacity 200ms, transform 0.2s;
      }
    `};
`

export const Background = styled.div`
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99;

  &.background-enter {
    opacity: 0;
  }

  &.background-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }

  &.background-exit {
    opacity: 1;
  }

  &.background-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`
