"use client"

import React from "react"
import styled, { css } from "styled-components"
import { Label } from "components/atoms/typography"
import { Box } from "components/atoms/_atoms"
import { BaseStyles } from "types/base-styles"

export type TagProps = BaseStyles & {
  border?: boolean
  label?: string
  icon?: string
  iconColor?: string
  type?: "primary" | "secondary"
  showBorder?: boolean
}

const Tag = ({
  showBorder = false,
  label,
  icon,
  iconColor,
  color,
  ...rest
}: TagProps) => {
  return (
    <Wrapper showBorder={showBorder} {...rest}>
      <Label type="secondary" icon={icon} color={color} iconColor={iconColor}>
        {label}
      </Label>
    </Wrapper>
  )
}

export default Tag

const Wrapper = styled(Box)<{
  showBorder: boolean
}>`
  width: max-content;
  display: flex;
  align-items: center;
  border-radius: 3px;
  transition: all 0.3s;
  border: none;
  background: ${({ theme, background = "background" }) => theme.color[background] ?? background};
  padding: ${({ theme, padding = "XXS" }) => theme.space[padding] ?? padding} ${({ theme, padding = "XS" }) => theme.space[padding] ?? padding};
  border-radius: 3px;

  label {
    transition: all 0.3s;
    cursor: pointer !important;
  }

  ${({ showBorder }) =>
    showBorder &&
    css`
      border: 1px solid ${({ theme }) => theme.color.border};
    `};
`
