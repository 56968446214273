import React, { useCallback, useState } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import Modal from "components/molecules/modal"
import { SentryService } from "services"
import Alert from "components/molecules/alert"
import Button from "components/atoms/button"
import { Row } from "components/atoms/_atoms"
import Input from "components/atoms/input"
import { makeId } from "utils/id"
import { RootState } from "store/store"

const NPSFeatureRequestModal = ({ show, setShow }: { show: boolean; setShow: (e: boolean) => void }) => {
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const [response, setResponse] = useState<boolean | string>(false)
  const [loading, setLoading] = useState(false)

  const { user } = useSelector((state: RootState) => state.auth)
  const { profile } = useSelector((state: RootState) => state.profile)

  const onSubmit = useCallback(
    async (el: any) => {
      try {
        setLoading(true)
        await axios.get(
          `https://hook.eu2.make.com/gqosvu7qbte9g3de8vu9zb2tki4q7sts?id=${makeId()}&username=${user?.username}&profile=${profile?.name}&message=${el.message}`,
        )
        setLoading(false)
        reset()
        setShow(false)
      } catch (e) {
        setLoading(false)
        setResponse("error")
        SentryService.error("[ERROR Make.com Feature Request]:", e)
        setTimeout(() => {
          setResponse(false)
        }, 3000)
      }
    },
    [],
  )

  return (
    <Modal
      icon="lightbulb"
      show={show}
      setShow={setShow}
      title="Masz pomysł na funckjonalność?"
      text="Podziel się swoim pomysłem na rozwój aplikacji."
      maxWidth="600px"
    >
      <>
        {response === "error" && (
          <Alert type="error" small marginBottom="S">
            Nie udało się przesłać formularza.
          </Alert>
        )}
        <Input
          name="message"
          label={"Opisz swój pomysł*"}
          error={errors.name}
          defaultValue={watch("message")}
          value={watch("message")}
          rhf={register("message", {
            required: true,
          })}
          multiline
          rows={6}
          marginBottom="S"
        />
        <Button loading={loading} width="100%" icon="send" onClick={handleSubmit(onSubmit)}>
          Wyślij
        </Button>
      </>
    </Modal>
  )
}

export default NPSFeatureRequestModal

const Wrapper = styled(Row)`
  display: flex;
  justify-content: center;
  grid-gap: ${({ theme }) => theme.space.S};
  align-items: center;
`
