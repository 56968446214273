import React from "react"
import styled from "styled-components"
import { IconButton } from "components/atoms/button"
import { Box } from "components/atoms/_atoms"

const ChatInput = ({
  loading,
  value,
  setValue,
  handleSubmit,
}: {
  loading: boolean
  value?: string
  setValue: (e: string) => void
  handleSubmit: () => void
}) => {
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      if (!loading) handleSubmit()
    }
  }

  return (
    <InputWrapper>
      <InputContent>
        <Input
          placeholder="Zadaj mi pytanie"
          onKeyDown={handleKeyDown}
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
        />
        <IconButton
          icon="send"
          size="35px"
          disabled={loading}
          background="primary"
          color="white"
          onClick={handleSubmit}
        />
      </InputContent>
    </InputWrapper>
  )
}

export default ChatInput

const Input = styled.input`
  font-family: "Manrope" !important;
  padding: ${({ theme }) => theme.space.S};
  margin: 0;
  font-size: ${({ theme }) => theme.font.M};
  font-weight: ${({ theme }) => theme.font.normal};
  line-height: normal;
  color: ${({ theme, color = "second" }) => theme.color[color]};
  background: none;
  border: none;
  flex: 1 1;
  height: 100%;
  width: 100%;

  &::placeholder {
    opacity: 0.5;
    font-weight: ${({ theme }) => theme.font.light};
  }

  &:focus {
    outline: none;
  }
`

const InputContent = styled(Box)`
  display: flex;
  align-items: center;
  background: white;
  box-shadow: ${({ theme }) => theme.variable.boxShadow};
  padding-right: ${({ theme }) => theme.space.XS};
  width: 100%;
`

const InputWrapper = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.color.backgroundDarker};
  display: flex;
  align-items: center;
`
