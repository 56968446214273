"use client";

import React, { ReactNode } from "react"
import styled, { css } from "styled-components"
import { Label } from "./Label"
import { H1, H2, H3, H4, LabelH1, LabelH2, LabelH3 } from "./index"
import { BaseStyles } from "types/base-styles";

export type TitleProps = BaseStyles & {
    children: ReactNode
    type?: "primary" | "secondary" | "third" | "fourth"
    elementType?: "h" | "label"
    styleType?: "primary" | "secondary" | "third" | "fourth"
    color?: string
    labelColor?: string
    label?: string
    center?: boolean
}

export const Title = ({ type = "secondary", styleType, color, children, label, labelColor = "primary", elementType = "h", center, ...rest } : TitleProps) => {
    let obj
    if(elementType === "h"){
        if (type === "primary") {
            obj = <H1 color={color} styleType={styleType ?? "primary"} {...rest}>{children}</H1>
        }
        if (type === "secondary") {
            obj = <H2 color={color} styleType={styleType ?? "secondary"} {...rest}>{children}</H2>
        }
        if (type === "third") {
            obj = <H3 color={color} styleType={styleType ?? "third"} {...rest}>{children}</H3>
        }
    }else {
        if (type === "primary") {
            obj = <LabelH1 color={color} styleType={styleType ?? "primary"} {...rest}>{children}</LabelH1>
        }
        if (type === "secondary") {
            obj = <LabelH2 color={color} styleType={styleType ?? "secondary"} {...rest}>{children}</LabelH2>
        }
        if (type === "third") {
            obj = <LabelH3 color={color} styleType={styleType ?? "third"} {...rest}>{children}</LabelH3>
        }
    }


    if (type === "fourth") {
        obj = <H4 color={color} styleType={styleType ?? "fourth"} {...rest}>{children}</H4>
    }

    return (
        <Wrapper center={center} className="title">
            {label && (
                <Label type="third" margin="0" color={labelColor} textTransform="uppercase">
                    {label}
                </Label>
            )}
            {obj}
        </Wrapper>
    )
}

const Wrapper = styled.div<BaseStyles & {
    center?: boolean
}>`
  svg {
    width: ${({ theme }) => theme.font.XL};
    margin: auto 0;
    margin-right: 7px;
  }

  ${({ center }) =>
    center &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      * {
        text-align: center;
      }
    `};
`
