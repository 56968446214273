import React from "react"
import styled from "styled-components"
import { MessageType } from "./types"
import ChatMessage, { ChatMessageLoading } from "./ChatMessage"
import { Box } from "components/atoms/_atoms"

const ChatContent = ({ loading, messages }: { loading: boolean; messages: MessageType[] }) => {
  return (
    <>
      <Content className="hide_scroll">
        {loading && <ChatMessageLoading />}
        {[...messages].reverse()?.map((item, index) => (
          <ChatMessage
            key={`${item.id}-${item.type}`}
            type={item.type}
            message={item.message}
            question={item.question}
            sources={item.sources?.filter(el => el.type !== "ustawa")}
          />
        ))}
      </Content>
    </>
  )
}

export default ChatContent

const AlertWrapper = styled(Box)`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  margin: 0 !important;
  padding: ${({ theme }) => theme.space.XS};
`

const Content = styled(Box)`
  flex: 1 1;
  padding: ${({ theme }) => theme.space.S};
  padding-top: 0;
  display: flex;
  flex-direction: column-reverse;
  overflow-anchor: auto !important;
  overflow: scroll;
  overflow-x: hidden;
  background: white;
  transform: rotateY(-1);

  > div {
    transform: translateZ(0);
    margin-top: ${({ theme }) => theme.space.XS};
  }
`
