import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import Button from "components/atoms/button"
import { Box, Row } from "components/atoms/_atoms"
import { P, Title } from "components/atoms/typography"
import Input from "components/atoms/input"
import { EMAIL_REGEX, PHONE_STRING_REGEX, WEBSITE_REGEX } from "utils/regex"
import { ProfileService } from "services/ProfileService"
import Alert from "components/molecules/alert"
import { RootState } from "store/store"
// import { SentryService } from "services/SentryService"
import { setProfile } from "store/profile"

const ProfileView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { profile } = useSelector((state: RootState) => state.profile)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: profile?.name,
      email: profile?.email,
      phone: profile?.phone,
      website: profile?.website,
      companySize: profile?.companySize,
    },
  })

  const [response, setResponse] = useState<boolean | string>(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (el: any) => {
    try {
      setLoading(true)
      const profile = await ProfileService.update(el)
      dispatch(setProfile(profile))
      setResponse("success")
    } catch (e) {
      // SentryService.error("[ERROR ProfileService.update]:", e)
      setResponse("error")
    } finally {
      setLoading(false)
      setTimeout(() => {
        setResponse(false)
      }, 3000)
    }
  }, [])

  return (
    <Wrapper>
      <Box>
        <Title marginBottom="XS">Dane profilu</Title>
        <P small marginBottom="0">
          Edytuj dane Twojego profilu
        </P>
      </Box>
      <Content>
        {response === "error" && (
            <Alert type="error" small marginBottom="S">
              Nie udało się zmienić danych Twojego profilu.
            </Alert>
        )}
        {response === "success" && (
            <Alert type="success" small marginBottom="S">
              Pomyślnie zmieniono dane Twojego profilu.
            </Alert>
        )}
        <Input
          name="name"
          label={"Nazwa profilu*"}
          error={errors.name}
          rhf={register("name", {
            required: true,
          })}
          marginBottom="S"
        />
        <StyledGrid marginBottom="S">
          <Input
            name="phone"
            label="Numer telefonu*"
            elementType="pattern"
            format="+48 ### ### ###"
            formattedValue={true}
            error={errors?.phone}
            value={watch("phone")}
            rhf={register("phone", {
              required: true,
              pattern: {
                value: PHONE_STRING_REGEX,
                message: "Niepoprawna wartość",
              },
            })}
          />
          <Input
            name="email"
            label="E-mail*"
            error={errors.email}
            rhf={register("email", {
              required: true,
              pattern: {
                value: EMAIL_REGEX,
                message: "Niepoprawny format email",
              },
            })}
          />
        </StyledGrid>
        <Input
          name="website"
          label="Strona internetowa"
          error={errors?.website}
          value={watch("website")}
          rhf={register("website", {
            pattern: {
              value: WEBSITE_REGEX,
              message: "Niepoprawny format adresu strony internetowej",
            },
          })}
          startAdornment="https://"
          marginBottom="S"
        />
        <Row justifyContent="flex-end">
          <Button loading={loading} icon="save" onClick={handleSubmit(onSubmit)}>
            Zapisz
          </Button>
        </Row>
      </Content>
    </Wrapper>
  )
}

export default ProfileView

const StyledGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
`

const Content = styled(Box)`
  width: 100%;
  max-width: 750px;
`

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M} 0;
  display: flex;
  justify-content: space-between;
  grid-gap: ${({ theme }) => theme.space.M};

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
