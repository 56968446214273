import React, { ReactNode } from "react"
import styled, { css } from "styled-components"
import { NavLink, NavLinkProps } from "react-router-dom"
import { StyledButtonProps, buttonStyle } from "components/atoms/button"
import Icon from "components/atoms/icon"
import { Row } from "components/atoms/_atoms"

export type LinkProps = NavLinkProps &
  StyledButtonProps & {
    children: ReactNode
    type?: "primary" | "secondary" | "third" | "fourth"
    icon?: string
    to: string
    disabledActive?: boolean
  }

const Link = ({
  children,
  to,
  type = "primary",
  icon,
  disabled,
  disabledActive,
  padding,
  hoverColor,
  hoverBackground,
  contentStyles,
  ...rest
}: LinkProps) => {
  return (
    <StyledLink
      to={to}
      styledType={type}
      disabled={disabled}
      padding={padding}
      hoverColor={hoverColor}
      hoverBackground={hoverBackground}
      disabledActive={disabledActive}
      {...rest}
    >
      <Row alignItems="center" {...contentStyles}>
        {icon && (
          <Row alignItems="center" marginRight="S">
            <Icon icon={icon} />
          </Row>
        )}
        {children}
      </Row>
    </StyledLink>
  )
}

export default Link

const StyledLink = styled(NavLink)<
  StyledButtonProps & {
    disabledActive?: boolean
  }
>`
  ${buttonStyle};

  ${({ disabledActive }) =>
    !disabledActive &&
    css`
      &.active {
        background: ${({ theme }) => theme.color.primary};
        color: ${({ theme }) => theme.color.white};
      }
    `}
`
