import { APIService } from "./APIService"
import {
  AddHistoryType, HistoryType,
  Page,
  ShortHistoryType,
} from "./types"

const getHistory = (page: number, size = 10) =>
  APIService.get<null, Page<ShortHistoryType>>(`/users/:userId/history?page=${page}&size=${size}`)
const addHistory = (body: AddHistoryType) => APIService.post<null>(`/users/:userId/history`, body)
const getHistoryDocuments = (id: string) =>
    APIService.get<null, HistoryType>(`/users/:userId/history/${id}`)
export const UserHistoryService = {
  getHistory,
  addHistory,
  getHistoryDocuments,
}
