const OBJ = {
    // courtType
    "APPEAL": "Sąd apelacyjny",
    "DISTRICT": "Sąd rejonowy",
    "REGIONAL": "Sąd okręgowy",
    "COMMON": "Sąd powszechny",
    "SUPREME": "Sąd Najwyższy",
    "ADMINISTRATIVE": "Sąd administracyjny",
    "CONSTITUTIONAL_TRIBUNAL": "Trybunał Konstytucyjny",
    "NATIONAL_APPEAL_CHAMBER": "Krajowa Izba Odwoławcza",
    // judgmentType
    "DECISION": "Postanowienie",
    "RESOLUTION": "Uchwała",
    "SENTENCE": "Wyrok",
    "REGULATION": "Zarządzenie",
    "REASONS": "Uzasadnienie",
    //SOURCES
    "SAOS": "System Analizy Orzeczeń Sądowych"
}

export const t = (e?: string) => {
    // @ts-ignore
    return OBJ[e] || ""
}
