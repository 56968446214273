"use client"

import React from "react"
import styled, { css } from "styled-components"
import { Label } from "components/atoms/typography"
import { common } from "components/atoms/_atoms"
import { BaseStyles } from "types/base-styles"
import Icon from "components/atoms/icon"

export type CheckboxProps = BaseStyles & {
  type?: "primary" | "secondary"
  name: string
  value: boolean
  label?: string
  icon?: string
  iconSize?: string
  iconColor?: string
  labelIcon?: string
  onChange?: (e: { name: string; value: boolean }) => void
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const Checkbox = ({
  type = "primary",
  name,
  value,
  label,
  icon,
  iconSize,
  iconColor,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {},
  ...rest
}: CheckboxProps) => {
  const handleOnChange = (e: boolean) => {
    onChange({
      name,
      value: e,
    })
  }

  return (
    <Option styleType={type} active={value} onClick={() => handleOnChange(!value)} {...rest}>
      {type === "primary" && (
        <CheckboxWrapper active={value}>
          <Icon icon="check" />
        </CheckboxWrapper>
      )}
      {/*// @ts-ignore*/}
      <Label type="secondary" icon={icon} iconSize={iconSize} iconColor={iconColor} textAlign="left" flex={1}>
        {label}
      </Label>
    </Option>
  )
}

export default Checkbox

const CheckboxWrapper = styled.div<
  BaseStyles & {
    active: boolean
  }
>`
  ${common};
  height: ${({ theme }) => theme.font.M};
  min-height: ${({ theme }) => theme.font.M};
  border-radius: 3px;
  width: ${({ theme }) => theme.font.M};
  max-width: ${({ theme }) => theme.font.M};
  margin-right: ${({ theme }) => theme.font.XS};
  background: none;
  border: 1px solid ${({ theme }) => theme.color.border};
  color: ${({ theme }) => theme.color.fourth};
  display: flex;
  justify-items: center;
  align-items: center;
  transition: all 0.3s;

  svg {
    font-size: 3px;
    width: 8px;
    height: 8px;
    display: block !important;
    margin-left: 2px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  ${({ active }) =>
    active &&
    css`
      border: 1px solid ${({ theme }) => theme.color.primary};
      background: ${({ theme }) => theme.color.primaryBackground};
      color: ${({ theme }) => theme.color.primary};

      svg {
        opacity: 1;
      }
    `};
`

const Option = styled.button<{
  active: boolean
  styleType: string
}>`
  ${common};
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
  color: ${({ theme }) => theme.color.third};
  border-radius: 3px;
  transition: all 0.3s;
  cursor: pointer;
  border: none;

  label {
    transition: all 0.3s;
    cursor: pointer !important;
  }

  ${({ styleType, active }) =>
    styleType === "secondary" &&
    css`
      background: none;
      padding: ${({ theme }) => theme.space.XXS} ${({ theme }) => theme.space.XS};
      border: 1px solid ${({ theme }) => theme.color.border};
      border-radius: 3px;

      ${active &&
      css`
        border: 1px solid ${({ theme }) => theme.color.primary};
        background: ${({ theme }) => theme.color.primary};

        label {
          color: ${({ theme }) => theme.color.white};
        }
      `};
    `}
`
