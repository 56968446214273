"use client"

import React, { ReactNode } from 'react';
import { ThemeProvider } from "styled-components"
import { baseTheme } from "theme";

export function StyledComponentsProvider({ children }: {
    theme?:string
    children?:ReactNode
}) {

    return (
        <ThemeProvider theme={baseTheme}>
            {children}
        </ThemeProvider>
    );
}
