import React from "react"
import { Outlet } from "react-router-dom"
import styled from "styled-components"
import PanelContentLayout from "components/layout/PanelContentLayout"
import { Box } from "components/atoms/_atoms"
import ChatHistory from "views/Panel/Chat/ChatHistory"

const Chat = () => {
  return (
    <PanelContentLayout
      icon="logotype"
      title="Chat z Parrot.ai"
      text="Zadaj dowolne pytanie związane z prawem"
      contentStyles={{
        overflow: "hidden",
      }}
    >
      <Wrapper>
        <ChatHistory />
        <Outlet />
      </Wrapper>
    </PanelContentLayout>
  )
}

export default Chat

const Wrapper = styled(Box)`
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-gap: ${({ theme }) => theme.space.S};
`
