import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { SentryService, UserHistoryService } from "services"
import { Box, Row } from "components/atoms/_atoms"
import LoaderBlock from "components/shared/blocks/LoaderBlock"
import { HistoryType } from "services/types"
import SignatureRowBlock from "components/shared/Documents/Signature/blocks/SignatureRowBlock"
import { Label, Title } from "components/atoms/typography"
import { IconButton } from "components/atoms/button"
import { formatDate } from "utils/date/date"

const HistoryInfoView = () => {
  const { historyId = "" } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<HistoryType | null>(null)

  const fetchData = async () => {
    //TODO: Change to use-query
    try {
      setLoading(true)
      const content = await UserHistoryService.getHistoryDocuments(historyId)
      setData(content)
    } catch (e) {
      SentryService.error("[ERROR UserHistoryService.getHistoryDocuments[:", e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Wrapper>
        <Row justifyContent="space-between" alignItems="center">
          <Row alignItems="center">
            <IconButton
              borderRadius="borderRadius"
              size="45px"
              icon="arrow-left"
              marginRight="XS"
              background="white"
              onClick={() => navigate(-1)}
            />
            <Title margin="0">{data?.title}</Title>
          </Row>
          <Label margin="0">{data?.dtCreated && formatDate(data?.dtCreated)}</Label>
        </Row>
        {loading && (
          <>
            <LoaderBlock height="80px" />
            <LoaderBlock height="80px" />
            <LoaderBlock height="80px" />
            <LoaderBlock height="80px" />
          </>
        )}
        {!loading &&
          data &&
          data.documents.length > 0 &&
          data.documents.map((el, index) => <SignatureRowBlock key={index} data={el} />)}
      </Wrapper>
      <Outlet />
    </>
  )
}

export default HistoryInfoView

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M} 0;
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.S};
`
