import React, { useState } from "react"
import styled from "styled-components"
import { DocumentSignatureService } from "services/DocumentSignatureService"
import { Box } from "components/atoms/_atoms"
import Button from "components/atoms/button"
import { useIsDocumentFavourite } from "store/folders"
import FavouriteModal from "components/modals/FavouriteModal"

const SignatureContentFooter = ({ id }: { id: string }) => {
  const isFavourite = useIsDocumentFavourite(id)
  const [favouriteModal, setFavouriteModal] = useState(false)

  return (
    <>
      <FooterContent>
        <Button
          type="secondary"
          icon={isFavourite ? "heart-fill" : "heart"}
          color={isFavourite ? "primary" : "secondary"}
          onClick={() => setFavouriteModal(true)}
        >
          {isFavourite ? "Usuń z ulubionych" : "Dodaj do ulubionych"}
        </Button>
        <a
          href={DocumentSignatureService.generateFileUrl(id)}
          target="_blank"
          download
          rel="noreferrer"
        >
          <Button icon="save">Pobierz</Button>
        </a>
      </FooterContent>
      <FavouriteModal id={id} show={favouriteModal} setShow={setFavouriteModal} />
    </>
  )
}

export default SignatureContentFooter

const FooterContent = styled(Box)`
  padding: ${({ theme }) => theme.space.S};
  display: flex;
  justify-content: flex-end;
  grid-gap: ${({ theme }) => theme.space.S};
`
