import React, { ReactNode } from "react"
import styled from "styled-components"
import { Box, Row } from "components/atoms/_atoms"
import { P, Title } from "components/atoms/typography"
import Button from "components/atoms/button"
import { IconBox } from "components/atoms/icon"
import { BaseStyles } from "types/base-styles"

const PanelContentLayout = ({
  icon,
  title,
  text,
  children,
  button,
  wrapperStyles,
  contentStyles,
}: {
  icon?: string
  title?: string
  text?: string | ReactNode
  button?: {
    title: string
    icon: string
    onClick: () => void
  }
  children: ReactNode | ReactNode[]
  wrapperStyles?: BaseStyles
  contentStyles?: BaseStyles
}) => {
  return (
    <Wrapper {...wrapperStyles}>
      <Row alignItems="center" justifyContent="space-between">
        <Box>
          <Row alignItems="center">
            {icon && <IconBox icon={icon} marginRight="S" />}
            <Title type="primary" fontSize="XXXL" marginBottom="0">
              {title}
            </Title>
          </Row>
          {text && (
            <Box marginTop="XS">
              {typeof text === "string" ? <P marginBottom="0">{text}</P> : text}
            </Box>
          )}
        </Box>
        {button && (
          <Button onClick={button.onClick} icon={button.icon}>
            {button.title}
          </Button>
        )}
      </Row>
      <Content {...contentStyles}>{children}</Content>
    </Wrapper>
  )
}

export default PanelContentLayout

const Content = styled(Box)`
  flex: 1 1;
  display: flex;
  flex-direction: column;
`

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M};
  grid-gap: ${({ theme }) => theme.space.S};
  flex: 1 1;
  height: 100svh;
  display: flex;
  flex-direction: column;
`
