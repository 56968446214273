import React from "react"
import styled from "styled-components"
import { DocumentSignatureType } from "services/types"
import { Label, P } from "components/atoms/typography"
import Tag from "components/atoms/tag"
import { t } from "utils/translator"
import Accordion from "components/molecules/accordion"
import { Box } from "components/atoms/_atoms"

const SignatureContentInfoPrimary = ({
  data,
  show,
  setShow,
}: {
  data: DocumentSignatureType
  show: boolean
  setShow: (e: boolean) => void
}) => {
  return (
    <Accordion icon="pin-02" title="Podstawowe informacje" show={show} setShow={setShow}>
      <InfoContent>
        {data?.division && (
          <DivisionWrapper>
            <Label type="third" marginBottom="S">
              Wydział orzekający
            </Label>
            <P marginBottom="XS">{data?.division?.name}</P>
            <Tag type="secondary" background="backgroundDarker" label={data?.division?.type} />
          </DivisionWrapper>
        )}
        {data?.division?.court && (
          <DivisionWrapper>
            <Label type="third" marginBottom="S">
              Sąd orzekający
            </Label>
            <P marginBottom="XS">{data?.division?.court?.name}</P>
            <Tag
              type="secondary"
              background="backgroundDarker"
              label={t(data?.division?.court.type)}
            />
          </DivisionWrapper>
        )}
        {data?.judges && data?.judges.length > 0 && (
          <P label="Sędziowie" margin="0">
            {data?.judges?.map(el => el.name)?.join(", ")}
          </P>
        )}
        {data?.legalBases && data?.legalBases.length > 0 && (
          <P label="Podstawy prawne orzeczenia" margin="0">
            {data?.legalBases?.join(", ")}
          </P>
        )}
        {data?.keywords && data?.keywords.length > 0 && (
          <P label="Słowa kluczowe" margin="0">
            {data?.keywords}
          </P>
        )}
        <P label="Zródło" margin="0">
          {t(data?.externalId?.source)}
        </P>
      </InfoContent>
    </Accordion>
  )
}

export default SignatureContentInfoPrimary

const InfoContent = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
`

const DivisionWrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.S};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  background: ${({ theme }) => theme.color.background};
`
