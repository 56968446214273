import React, { useEffect } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Box } from "components/atoms/_atoms"
import { useChats } from "hooks/useChats"
import Link from "components/atoms/link"
import Button from "components/atoms/button"
import { makeId } from "utils/id"
import { addChat, defaultMessage } from "store/chat"
import { Label } from "components/atoms/typography"

const ChatHistory = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { draftChat, chats } = useChats()

  const handleAddChat = () => {
    if (draftChat) {
      navigate(`/panel/chat/${draftChat.id}`)
    } else {
      const id = makeId(5)
      const chat = {
        id,
        isDraft: true,
        messages: [defaultMessage],
        filters: null,
        date: new Date(),
      }

      dispatch(addChat(chat))
      navigate(`/panel/chat/${id}`)
    }
  }

  useEffect(() => {
    handleAddChat()
  }, [])

  return (
    <Wrapper>
      {/*<IconButton*/}
      {/*  borderRadius="45px"*/}
      {/*  size="45px"*/}
      {/*  icon="edit"*/}
      {/*  background="primary"*/}
      {/*  color="white"*/}
      {/*  onClick={handleAddChat}*/}
      {/*/>*/}
      <Content>
        <Box padding="XS">
          <Label type="third" color="third">
            Historia rozmów
          </Label>
        </Box>
        {chats.map(chat => (
          <ChatLink type="third" key={chat.id} to={`/panel/chat/${chat.id}`}>
            {chat.messages.filter(el => el.type === "human").length > 0
              ? chat.messages.filter(el => el.type === "human")[0].message
              : "Wpisz czego potrzebujesz"}
          </ChatLink>
        ))}
      </Content>
      <Button icon="edit" width="100%" onClick={handleAddChat}>
        Utwórz nową rozmowę
      </Button>
    </Wrapper>
  )
}

export default ChatHistory

const ChatLink = styled(Link)`
  width: 100% !important;
  justify-content: flex-start;
  //background: ${({ theme }) => theme.color.background} !important;
  //border-bottom: 1px solid ${({ theme }) => theme.color.backgroundDarker};

  > div {
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.active {
    //border-color: rgba(0, 0, 0, 0);
    background: ${({ theme }) => theme.color.background} !important;
    border-radius: ${({ theme }) => theme.variable.borderRadius};
  }
`

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  grid-gap: ${({ theme }) => theme.space.XS};
  flex: 1 1;
`

const Wrapper = styled(Box)`
  height: 100%;
  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  grid-gap: ${({ theme }) => theme.space.XS};
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.backgroundDarker};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  padding: ${({ theme }) => theme.space.XS};
`
