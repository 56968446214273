import React from "react"
import styled from "styled-components"
import { Box } from "components/atoms/_atoms"
import QucikExplanationBlock from "components/shared/blocks/QucikExplanationBlock"
import { DocumentSignatureType } from "services/types"
import { useCustomQuery } from "hooks/useCustomQuery"
import { AIService } from "services/AIService"
import SignatureContentInnerContentText from "components/shared/Documents/Signature/components/SignatureContentInnerContentText"

const SignatureContentInnerContent = ({ data, q }: { data: DocumentSignatureType; q: string }) => {
  const isValidQ = !!q && q !== ""
  const { isLoading: isLoadingExplanation, data: explanation } = useCustomQuery<string>(
    { key: ["explanation", data.id, q] },
    () => AIService.quickAnswer({ id: data.id, message: q }),
    isValidQ,
  )

  return (
    <Wrapper>
      {isValidQ && (
        <QucikExplanationBlock isLoading={isLoadingExplanation} marginBottom="S">
          {explanation}
        </QucikExplanationBlock>
      )}
      <SignatureContentInnerContentText id={data.id} text={data?.content} />
    </Wrapper>
  )
}

export default SignatureContentInnerContent

const Wrapper = styled(Box)`
  flex: 1;
  overflow: scroll;
  overflow-x: hidden;
`
