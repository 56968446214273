import React from "react"
import styled from "styled-components"
import { Box, Row } from "components/atoms/_atoms"
import LoaderBlock from "components/shared/blocks/LoaderBlock"
import SignatureBlock from "components/shared/Documents/Signature/blocks/SignatureBlock"
import { AISourceDocument } from "services/AIService"
import { P } from "components/atoms/typography"

const SearchContent = ({
  loading,
  data,
  setSelectedDocumentId,
}: {
  loading: boolean
  data?: AISourceDocument[] | null
  setSelectedDocumentId: (e: string | null) => void
}) => {
  return (
    <Wrapper>
      {!loading &&
        data &&
        data.length > 0 &&
        data
          .filter(function (item, pos) {
            return data?.findIndex(el => el.metadata.id === item.metadata.id) == pos
          })
          .map((document, index) => (
            <SignatureBlock
              key={index}
              suggested={index === 0}
              data={document}
            />
          ))}
      {loading && (
        <>
          <LoaderBlock height="150px" />
          <LoaderBlock height="150px" />
          <LoaderBlock height="150px" />
        </>
      )}
      {/*{!loading && data?.length && data?.length > 0 && (*/}
      {/*  <Button type="secondary" margin="0 auto" submit form="search_form">Zobacz więcej</Button>*/}
      {/*)}*/}
      {!loading && data?.length === 0 && (
        <Row marginTop="M" justifyContent="center">
          <P small>Brak dokumentów spełniających kryteria</P>
        </Row>
      )}
    </Wrapper>
  )
}

export default SearchContent

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
`
