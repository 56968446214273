import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { Box, Row } from "components/atoms/_atoms"
import { IconButton } from "components/atoms/button"
import { Title } from "components/atoms/typography"
import Tag from "components/atoms/tag"
import { formatDate } from "utils/date/date"
import { t } from "utils/translator"
import { DocumentSignatureType } from "services/types"

const SignatureContentHeader = ({
  data,
  showGoBack = true,
  showTitle = true,
}: {
  data: DocumentSignatureType
  showGoBack?: boolean
  showTitle?: boolean
}) => {
  const navigate = useNavigate()

  return (
    <TitleContent marginBottom="S">
      <Box>
        {/*<IconBox icon="cv" marginBottom="S" />*/}
        <Row alignItems="center">
          {showGoBack && (
            <IconButton
              borderRadius="borderRadius"
              size="45px"
              icon="arrow-left"
              marginRight="XS"
              background="background"
              onClick={() => navigate(-1)}
            />
          )}
          {showTitle && (
            <Title margin="0" marginRight="S">
              {data?.name}
            </Title>
          )}
          <TagsWrapper>
            {data?.date && <Tag icon="clock" iconColor="primary" label={formatDate(data?.date)} />}
            <Tag icon="decision" iconColor="primary" label={t(data?.judgmentType)} />
            <Tag icon="court" iconColor="primary" label={t(data?.courtType)} />
          </TagsWrapper>
        </Row>
      </Box>
    </TitleContent>
  )
}

export default SignatureContentHeader

const TagsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const TitleContent = styled(Box)`
  padding: ${({ theme }) => theme.space.S};
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
