"use client";

import React, { ReactNode } from "react"
import SCStyled, { css } from "styled-components"
import { styled } from "@mui/material/styles"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import {
  NumericFormat,
  NumericFormatProps,
  PatternFormat,
  PatternFormatProps,
} from "react-number-format"
import { baseTheme } from "theme"
import Spinner from "components/atoms/spinner"
import { Absolute, Box, common } from "components/atoms/_atoms"
import { BaseStyles } from "types/base-styles"
import { Label, P } from "components/atoms/typography"
import Icon from "components/atoms/icon"

const StyledTextField = styled(TextField)(() => {
  return {
    "width": "100%",
    "background": "none",
    ".MuiInputBase-root": {
      "box-sizing": "border-box",
      "background": "none",
      "padding-left": baseTheme.font.XS,
      "padding-top": baseTheme.font.XS,
      "padding-bottom": baseTheme.font.XS,
      "borderRadius": baseTheme.variable.borderRadius,

      "&:before, &:after": {
        display: "none",
      },

      "input, textarea": {
        "box-sizing": "border-box",
        "color": baseTheme.color.second,
        "fontSize": baseTheme.font.L,
        "lineHeight": baseTheme.font.XL,
        "fontWeight": baseTheme.font.light,
        "padding": "0",
        "padding-right": "10px",
      },
      "input": {
        height: 25,
      },
    },
    ".MuiInputAdornment-root": {
      margin: "0 !important",
      fontSize: baseTheme.font.L,
      lineHeight: baseTheme.font.XL,
      fontWeight: baseTheme.font.light,

      p: {
        fontSize: baseTheme.font.L,
        lineHeight: baseTheme.font.XL,
        fontWeight: baseTheme.font.light,
      },
    },
  }
})

export type InputProps = BaseStyles & {
  elementType?: "text" | "pattern" | "numeric"
  type?: "text" | "password"
  label?: string
  defaultValue?: string | number
  value?: string | number
  icon?: string
  name: string
  width?: unknown
  placeholder?: string
  background?: string
  error?: {
    type?: string
    message?: string
  }
  loading?: boolean
  disabled?: boolean
  format?: string
  formattedValue?: boolean
  decimalScale?: number
  decimalSeparator?: string
  allowNegative?: boolean
  endAdornment?: string
  startAdornment?: string
  textAlign?: string
  onChange?: (e: { name: string; value: any }) => void
  rhf?: object & { ref?: React.Ref<any> }
  multiline?: boolean
  rows?: number
  maxRows?: number
  inputParams?: any
}

const PatterFormatCustom = React.forwardRef<
  PatternFormatProps,
  {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
    value: string | number
    format: string
    formattedValue: boolean
  }
>(function PatterFormatCustom(props) {
  const { onChange = () => {}, name, value, format, formattedValue, ...other } = props

  return (
    <PatternFormat
      {...other}
      value={value || ""}
      onValueChange={values => {
        onChange({
          // @ts-ignore
          target: {
            name: name,
            value: formattedValue ? values.formattedValue : values.value,
          },
        })
      }}
      format={format}
    />
  )
})

const NumericFormatCustom = React.forwardRef<
  NumericFormatProps,
  {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
    value: string | number
    decimalScale?: number
    decimalSeparator?: string
    allowNegative?: boolean
  }
>(function NumericFormatCustom(props) {
  const { name, value, onChange = () => {}, ...other } = props

  return (
    <NumericFormat
      {...other}
      value={value || ""}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            // @ts-ignore
            value: values?.floatValue ?? "",
          },
        })
      }}
    />
  )
})

export const ErrorMessage = ({ children }: { children?: ReactNode }) => {
  return <ErrorFormHelperText>{children}</ErrorFormHelperText>
}

const Input = ({
  elementType = "text",
  type = "text",
  label,
  defaultValue,
  value,
  name,
  background = "none",
  width = "100%",
  icon,
  error,
  placeholder,
  loading,
  disabled,
  startAdornment,
  endAdornment,
  format = "+48 ### ### ###",
  formattedValue = false,
  decimalScale = 2,
  decimalSeparator = ",",
  allowNegative = false,
  onChange = () => {},
  rhf = {},
  multiline,
  rows,
  maxRows,
  inputParams,
  ...rest
}: InputProps) => {
  const { ref, ...rhfRest } = rhf

  const handleOnChange = (e: any) => {
    onChange({
      name,
      value: e.target.value,
    })
  }

  // @ts-ignore
  return (
    <Box {...rest}>
      {label && (
        <Label type="secondary" marginBottom="XS">
          {label}
        </Label>
      )}
      <StyledBox width={width} hasIcon={!!icon} background={background}>
        <StyledTextField
          inputRef={ref}
          onChange={handleOnChange}
          defaultValue={value}
          value={value}
          error={!!error}
          disabled={disabled}
          placeholder={placeholder}
          variant="outlined"
          type={type}
          InputProps={{
            inputComponent:
              elementType === "numeric"
                ? (NumericFormatCustom as any)
                : elementType === "pattern"
                ? (PatterFormatCustom as any)
                : undefined,
            inputProps: {
              decimalScale,
              decimalSeparator,
              allowNegative,
              format,
              formattedValue,
              value,
            },
            startAdornment: startAdornment && (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ),
            endAdornment: !loading && endAdornment && (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
          }}
          multiline={multiline}
          rows={rows}
          maxRows={maxRows}
          {...inputParams}
          {...rhfRest}
        />
        {loading && (
          <SpinnerWrapper>
            <Spinner size="15px" borderWidth={3} />
          </SpinnerWrapper>
        )}
        {icon && (
          <Absolute
            left={baseTheme.space.S}
            top="50%"
            style={{ transform: "translateY(-50%)" }}
            display="flex"
            alignItems="center"
          >
            <Icon size="15px" icon={icon} />
          </Absolute>
        )}
      </StyledBox>
      {error && (
        <ErrorMessage>
          {error?.message ?? "To pole jest wymagane"}
        </ErrorMessage>
      )}
    </Box>
  )
}

export default Input

const ErrorFormHelperText = SCStyled(P)`
    color: ${({ theme }) => theme.color.red} !important;
    font-size: ${({ theme }) => theme.font.S};
    margin: 3px 10px;
`

const SpinnerWrapper = SCStyled.div`
    position: absolute;
    right: ${({ theme }) => theme.space.S};
    top: 50%;
    transform: translateY(-50%);
`

const StyledBox = SCStyled(Box)<
  BaseStyles & {
    hasIcon: boolean
  }
>`
  ${common};
  min-width: ${({ width }) => width};
  position: relative;
  margin: 0;
    
  * {
    box-sizing: border-box;
    font-family: 'Manrope' !important;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  
  .MuiInputBase-root {
    overflow: hidden;
    border: 1px solid ${({ theme }) => theme.color.border};
    border-radius: ${({ theme }) => theme.variable.borderRadius} !important;
    
    ${({ hasIcon }) =>
      hasIcon &&
      css`
        padding-left: 39px !important;
      `};
      
      .MuiFormHelperText-root {
       &.Mui-error {
        color: ${({ theme }) => theme.color.red} !important;
        margin: 3px 10px;
       }
     }
  }
  `
