import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { Box, Row } from "components/atoms/_atoms"
import LoaderBlock from "components/shared/blocks/LoaderBlock"
import { ShortHistoryType } from "services/types"
import { SentryService, UserHistoryService } from "services"
import { Pagination } from "components/molecules/pagination"
import HistoryBlock from "components/shared/blocks/HistoryBlock"
import { P } from "components/atoms/typography"

const HistoryView = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState<ShortHistoryType[]>([])

  const fetchData = async (newPage: number) => {
    //TODO: Change to use-query
    try {
      setLoading(true)
      const { content, totalPages } = await UserHistoryService.getHistory(newPage)
      setTotalPages(totalPages)
      setData(content)
    } catch (e) {
      SentryService.error("[ERROR ProfileService.getUsers]:", e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(page)
  }, [page])

  return (
    <>
      <Wrapper>
        <Content>
          {loading && (
            <>
              <LoaderBlock height="70px" />
              <LoaderBlock height="70px" />
              <LoaderBlock height="70px" />
              <LoaderBlock height="70px" />
            </>
          )}
          {!loading &&
            data.length > 0 &&
            data.map((el, index) => <HistoryBlock key={index} data={el} />)}
          {!loading && data.length > 0 && (
            <Pagination defaultPage={page + 1} totalPages={totalPages} onChange={e => setPage(e - 1)} />
          )}
          {!loading && data.length === 0 && (
            <Row marginTop="M" justifyContent="center">
              <P small>Brak wyszukiwań</P>
            </Row>
          )}
        </Content>
      </Wrapper>
    </>
  )
}

export default HistoryView

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
  margin-top: ${({ theme }) => theme.space.S};
`

const Wrapper = styled(Box)`

`
