import React, { useState } from "react"
import styled from "styled-components"
import SignatureContentHeader from "components/shared/Documents/Signature/components/SignatureContentHeader"
import SignatureContentInnerContent from "components/shared/Documents/Signature/components/SignatureContentInnerContent"
import SignatureContentInfo from "components/shared/Documents/Signature/components/SignatureContentInfo"
import { Box } from "components/atoms/_atoms"
import { DocumentSignatureType } from "services/types"
import DocumentChat from "components/shared/Documents/components/DocumentChat"
import SignatureContentFooter from "components/shared/Documents/Signature/components/SignatureContentFooter"

const Signature = ({
  showTitle,
  showGoBack,
  data,
  q,
}: {
  data: DocumentSignatureType
  q: string
  showGoBack?: boolean
  showTitle?: boolean
}) => {
  const [showChat, setShowChat] = useState(false)

  return (
    <>
      <SignatureContentHeader showGoBack={showGoBack} showTitle={showTitle} data={data} />
      <Content showChat={showChat}>
        <SignatureContentInnerContent data={data} q={q} />
        <SignatureContentInfo data={data} showChat={showChat} setShowChat={setShowChat} />
        {showChat && <DocumentChat id={data?.id} show={showChat} setShow={setShowChat} />}
      </Content>
      <SignatureContentFooter id={data?.id} />
    </>
  )
}

export default Signature

const Content = styled(Box)<{
  showChat: boolean
}>`
  flex: 1 1;
  display: grid;
  grid-template-columns: ${({ showChat }) => (showChat ? "2fr 1fr 400px" : "2fr 1fr")};
  grid-gap: ${({ theme }) => theme.space.S};
  overflow: scroll;
  padding: 0 ${({ theme }) => theme.space.S};
`
