"use client"

import styled, { css } from "styled-components"
import {
  background,
  border,
  color,
  flexbox,
  layout,
  opacity,
  position,
  size,
  space,
  textAlign,
  textStyle,
} from "styled-system"
import { BaseStyles, CustomSizeType } from "types/base-styles"

const defaultCommon = [
  background,
  space,
  flexbox,
  border,
  color,
  position,
  layout,
  size,
  opacity,
  textAlign,
  textStyle,
]

const spaces = css<BaseStyles>`
  ${({ theme, margin }) =>
    margin &&
    css`
      margin: ${theme.space[margin] ?? margin};
    `};
  ${({ theme, marginTop }) =>
    marginTop &&
    css`
      margin-top: ${theme.space[marginTop] ?? marginTop};
    `};
  ${({ theme, marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${theme.space[marginBottom] ?? marginBottom};
    `};
  ${({ theme, marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${theme.space[marginLeft] ?? marginLeft};
    `};
  ${({ theme, marginRight }) =>
    marginRight &&
    css`
      margin-right: ${theme.space[marginRight] ?? marginRight};
    `};

  ${({ theme, padding }) =>
    padding &&
    css`
      padding: ${theme.space[padding] ?? padding};
    `};
  ${({ theme, paddingTop }) =>
    paddingTop &&
    css`
      padding-top: ${theme.space[paddingTop] ?? paddingTop};
    `};
  ${({ theme, paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: ${theme.space[paddingBottom] ?? paddingBottom};
    `};
  ${({ theme, paddingLeft }) =>
    paddingLeft &&
    css`
      padding-left: ${theme.space[paddingLeft] ?? paddingLeft};
    `};
  ${({ theme, paddingRight }) =>
    paddingRight &&
    css`
      padding-right: ${theme.space[paddingRight] ?? paddingRight};
    `};
`

export const common = css<BaseStyles>`
  box-sizing: border-box;
  ${defaultCommon};
  ${spaces}
`

export const blockPaddingTop = css<{
  padding?: string | CustomSizeType
}>`
  padding-top: ${({ theme, padding = "XL" }) => theme.space[padding] ?? padding};
`

export const blockPaddingBottom = css<{
  padding?: string | CustomSizeType
}>`
  padding-bottom: ${({ theme, padding = "XL" }) => theme.space[padding] ?? padding};
`

export const blockPadding = css`
  ${blockPaddingTop};
  ${blockPaddingBottom};
`

export const blockStyles = css<{
  margin?: string | CustomSizeType
}>`
  width: 100%;
  margin: ${({ theme, margin = "XL" }) => theme.space[margin] ?? margin} auto;
`

export const Box = styled.div<BaseStyles>`
  ${common};
  background: ${({ theme, background }) => background && (theme.color[background] ?? background)};
  position: relative;
`

export const Column = styled.div<BaseStyles>`
  ${common};
  display: flex;
  flex-direction: ${({ flexDirection = "column" }) => flexDirection};
`

export const Row = styled.div<BaseStyles>`
  ${common};
  display: flex;
  background: ${({ theme, background }) => background && (theme.color[background] ?? background)};
`

export const Grid = styled.div`
  ${common};
  display: grid;
`

export const Absolute = styled(Box)`
  ${common};
  position: absolute !important;
`
