"use client";

import React, { ReactNode } from "react"
import styled, { css } from "styled-components"
import Icon from "components/atoms/icon"
import { common } from "components/atoms/_atoms"
import { BaseStyles } from "types/base-styles";

export type LabelProps = BaseStyles & {
  children: ReactNode
  icon?: string
  iconText?: string
  color?: string
  iconColor?: string
  iconSize?: string
  type?: string
  className?: string
}
export const Label = ({ children, icon, iconText, color, iconColor, iconSize, type, ...rest } : LabelProps) => {
  return (
    <Wrapper isIcon={!!icon} iconSize={iconSize} color={color} type={type} {...rest}>
      {icon && <Icon icon={icon} color={iconColor ?? color} />}
      {iconText && <StyledSpan color={iconColor ?? color}>{iconText}</StyledSpan>}
      {children}
    </Wrapper>
  )
}

const StyledSpan = styled.span<BaseStyles>`
  font-size: ${({ theme }) => theme.font.S};
  margin-right: ${({ theme }) => theme.font.XS};
  color: ${({ theme, color = "second" }) => theme.color[color] ?? color};
`

export const Wrapper = styled.label<LabelProps & {
  isIcon?: boolean
  iconSize?: string
}>`
    ${common};
    font-family: 'Manrope' !important;
    font-size: ${({ theme, fontSize = "L" }) => theme.font[fontSize]};
    font-weight: ${({ theme, fontWeight = "normal" }) => theme.font[fontWeight]};
    line-height: ${({ lineHeight = "normal" }) => lineHeight};
    text-transform: ${({ textTransform }) => textTransform};
    color: ${({ theme, color = "second" }) => theme.color[color] ?? color};
    display: flex;
    align-items: center;
    cursor: inherit;

    ${({ type }) =>
            type === "secondary" &&
            css`
                font-size: ${({ theme }) => theme.font.M};
                font-weight: ${({ theme }) => theme.font.normal};
            `}

    ${({ type }) =>
            type === "third" &&
            css`
                font-size: ${({ theme }) => theme.font.S};
                font-weight: ${({ theme }) => theme.font.normal};
            `}

    ${({ isIcon, iconSize = "L" }) =>
            isIcon &&
            css`
                display: flex;
                justify-content: flex-start;
                align-items: center;

                svg {
                    height: ${({ theme }) => theme.font[iconSize]};
                    min-height: ${({ theme }) => theme.font[iconSize]};
                    margin: auto 0;
                    margin-right: ${({ theme }) => theme.font.XS};
                }
            `}
`
