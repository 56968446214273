import { createSlice } from "@reduxjs/toolkit"
import { ProfileType } from "services/types"

interface ProfileState {
  profileIds: string[]
  profile: null | ProfileType
}

// Define the initial state using that type
const initialState: ProfileState = {
  profileIds: [],
  profile: null,
}

export const profileSlice = createSlice({
  name: "counter",
  initialState: initialState,
  reducers: {
    initProfileState: state => {
      state.profileIds = []
      state.profile = null
    },
    setProfileIds: (state, action) => {
      state.profileIds = action.payload
    },
    setProfile: (state, action) => {
      state.profile = action.payload
    },
    updateProfile: (state, action) => {
      state.profile = {
        ...state.profile,
        ...action.payload,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setProfileIds, setProfile, updateProfile, initProfileState } = profileSlice.actions

export default profileSlice.reducer
