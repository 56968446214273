import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface UIState {
  showMenu?: boolean
}

// Define the initial state using that type
const initialState: UIState = {
  showMenu: true,
}

export const uiSlice = createSlice({
  name: "ui",
  initialState: initialState,
  reducers: {
    updateShowMenu: (state, action: PayloadAction<boolean>) => {
      state.showMenu = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateShowMenu } = uiSlice.actions

export default uiSlice.reducer
