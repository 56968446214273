import React, { useEffect } from "react"
import { Outlet, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import PanelContentLayout from "components/layout/PanelContentLayout"
import { useCustomQuery } from "hooks/useCustomQuery"
import { DocumentSignatureService } from "services/DocumentSignatureService"
import { DocumentSignatureType } from "services/types"
import { updateShowMenu } from "store/ui"

const Document = () => {
  const dispatch = useDispatch()
  const { id = "" } = useParams()

  const { isLoading, data } = useCustomQuery<DocumentSignatureType>({ key: ["document", id] }, () =>
    DocumentSignatureService.getById(id),
  )

  useEffect(() => {
    dispatch(updateShowMenu(false))
  }, [])

  return (
    <PanelContentLayout title={data?.name}>
      <Outlet />
    </PanelContentLayout>
  )
}

export default Document
