"use client";

import React from "react"
import IcoMoon from "react-icomoon"
import styled, { css } from "styled-components"
import { common, Row } from "components/atoms/_atoms"
import { BaseStyles } from "types/base-styles"

// eslint-disable-next-line @typescript-eslint/no-var-requires
const iconSet = require("./selection.json")

export const IconBox = ({ size = 40, small = false, icon, ...rest } : BaseStyles & {
    size?: number
    small?: boolean
    icon: string
}) => {

    const finalSize = small ? 25 : size

    return (
      <Row
        width={`${finalSize}px`}
        height={`${finalSize}px`}
        background="primaryBackground"
        alignItems="center"
        justifyContent="center"
        borderRadius="50%"
        {...rest}
      >
        <Icon size={`${finalSize/2}px`} icon={icon} color="primary" />
      </Row>
    )
}

const Icon = ({ icon, size, ...props } : BaseStyles & {
    icon: string
    size?: string | number
}) => {
    return <StyledIcoMoon icon={icon} size={size} className="icon" iconSet={iconSet} {...props} />
}

const StyledIcoMoon = styled(IcoMoon)<BaseStyles>`
  ${common};
  color: ${({ theme, color = "secondary" }) => theme.color[color] ?? color};
  
  ${({ theme, size }) => size && css`
    height: ${theme.font[size] ?? size};
    min-height: ${theme.font[size] ?? size};
    width: ${theme.font[size] ?? size};
    min-width: ${theme.font[size] ?? size};
  `}
`

export default Icon
