import React, { useCallback } from "react"
import styled from "styled-components"
import { Label } from "components/atoms/typography"
import { Box } from "components/atoms/_atoms"
import { BaseStyles } from "types/base-styles"
import Checkbox from "components/atoms/checkbox";

export type RadioCheckboxesProps = BaseStyles & {
    name: string
    type?: "primary" | "secondary"
    value: any[]
    options: number[][] | string [][]
    label?:string
    labelIcon?:string
    iconColor?:string
    onChange?: (e: { name: string, value: any }) => void
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const CheckboxesGroup = ({ name, type = "primary", value = [], options = [], label, labelIcon, iconColor, onChange = () => {}, ...rest } : RadioCheckboxesProps) => {
    const handleOnChange = (e: any[]) => {
        onChange({
            name,
            value: e
        })
    }

    const handleClick = useCallback(
        ({ name, value : elValue } : { name: string; value: boolean; }) => {
            if (elValue) {
                const arr = [...value, name]
                handleOnChange(arr)
            } else {
                handleOnChange(value.filter(e => e !== name))
            }
        },
        [handleOnChange, value, options],
    )

    return (
      <Wrapper {...rest}>
        {label && (
          <Label icon={labelIcon} marginBottom="S">
            {label}
          </Label>
        )}
        <Content gridGap={type === "primary" ? "S" : "XS"}>
          {options.map((el: number[] | string[]) => (
            <Checkbox
              key={`${el[1]}`}
              type={type}
              name={`${el[1]}`}
              value={value?.includes(el[1])}
              onChange={handleClick}
              label={`${el[0]}`}
              icon={`${el[2]}`}
              iconColor={iconColor}
              iconSize="S"
            />
          ))}
        </Content>
      </Wrapper>
    )
}

export default CheckboxesGroup

const Content = styled(Box)<{
    gridGap: string
}>`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${({ theme, gridGap }) => theme.space[gridGap] ?? gridGap};
`

const Wrapper = styled(Box)`
  *{
    box-sizing: border-box;
    font-family: 'Manrope' !important;
  }
`
