import axios from "axios"
import qs from "qs"
import { LocalStorageService } from "./LocalStorageService"
import { getUserIdFromJWT } from "utils/token"

export const API_URL = process.env.REACT_APP_API_URL

export const APIService = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  paramsSerializer: params => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
    })
  },
})

APIService.interceptors.request.use(
  async config => {
    const token = await LocalStorageService.getObject("token")
    config.baseURL = API_URL
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`
    }
    if (config.url!.includes(":userId") && token) {
      const id = getUserIdFromJWT(token)
      config.url = config.url!.replace(":userId", id)
    }
    if (config.url!.includes(":profileId")) {
      const profileId = await LocalStorageService.getObject("profileId")
      if (profileId) {
        config.url = config.url!.replace(":profileId", profileId)
      }
    }

    return config
  },
  error => {
    console.log(error.data)
    // eslint-disable-next-line no-undef
    Promise.reject(error)
  },
)

APIService.interceptors.response.use(
  async response => {
    return response.data
  },
  async error => {
    // eslint-disable-next-line no-undef
    return Promise.reject(error)
  },
)
