import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { RootState } from "./store"
import { UserFolderType } from "services/types"

interface FolderState {
  loading: boolean
  folders: UserFolderType[]
}

// Define the initial state using that type
const initialState: FolderState = {
  loading: false,
  folders: [],
}

export const folderSlice = createSlice({
  name: "folder",
  initialState: initialState,
  reducers: {
    initGetFolders: state => {
      state.loading = true
      state.folders = []
    },
    setFolders: (state, action) => {
      state.loading = false
      state.folders = action.payload
    },
    addFolder: (state, action) => {
      state.folders = [...state.folders, action.payload]
    },
    deleteFolder: (state, action: PayloadAction<string>) => {
      state.folders = state.folders.filter(folder => folder.id !== action.payload)
    },
    editFolder: (state, action: PayloadAction<UserFolderType>) => {
      const index = state.folders.findIndex(el => el.id === action.payload.id)
      const folders = state.folders
      folders[index] = action.payload
      state.folders = folders
    },
    addDocumentToFolder: (
      state,
      action: PayloadAction<{
        documentId: string
        folderId: string
      }>,
    ) => {
      const index = state.folders.findIndex(el => el.id === action.payload.folderId)
      const folder = state.folders[index]
      folder.documentIds = [...folder.documentIds, action.payload.documentId]
      folder.count += 1
      const folders = state.folders
      folders[index] = folder
      state.folders = folders
    },
    deleteDocumentToFolder: (
        state,
        action: PayloadAction<{
          documentId: string
          folderId: string
        }>,
    ) => {
      const index = state.folders.findIndex(el => el.id === action.payload.folderId)
      const folder = state.folders[index]
      folder.documentIds = folder.documentIds.filter(el => el !== action.payload.documentId)
      folder.count -= 1
      const folders = state.folders
      folders[index] = folder
      state.folders = folders
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  initGetFolders,
  setFolders,
  addFolder,
  deleteFolder,
  editFolder,
  addDocumentToFolder,
  deleteDocumentToFolder,
} = folderSlice.actions

export const useFolder = (id?: string) =>
  useSelector((state: RootState) => {
    const index = state.folders.folders.findIndex(el => el.id === id)
    return state.folders.folders[index]
  })

export const useIsDocumentFavourite = (id: string) =>
  useSelector((state: RootState) => {
    let isFavourite = false
    state.folders.folders.forEach(folder => {
      if (folder.documentIds.includes(id)) {
        isFavourite = true
      }
    })
    return isFavourite
  })

export default folderSlice.reducer
