export const IS_ONE_DIGIT = /^[0-9]$/g
export const INT_REGEX = /^[0-9]*?$/
export const CONFIRM_CODE_REGEX = /^[0-9]{6}$/
export const DOUBLE_REGEX = /^[0-9]*(([,]|[.])([0-9]|[0-9][0-9])?)?$/
export const SLUG_REGEX = /^[a-z0-9.]*$/

export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const WEBSITE_REGEX =
    /^[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$/

export const PHONE_REGEX = /^\+48\d{9}$/
export const PHONE_STRING_REGEX = /^\+48 \d{3} \d{3} \d{3}$/
export const POSTCODE_REGEX = /^\d{2}-\d{3}$/
export const PESEL_REGEX = /^\d{11}?$/
export const NIP_REGEX = /^\d{10}?$/
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/

export const testRegex = (value: string, regex: RegExp | string) => {
    return new RegExp(regex).test(value)
}
