import { API_URL, APIService } from "./APIService"
import { DocumentSignatureDivision, DocumentSignatureJudge, DocumentSignatureType, Page } from "./types"

const get = (q: string, judgmentType: string | null, courtType: string | null) => {
  const params = new URLSearchParams({})
  params.set("q", q)
  if (judgmentType) params.set("judgmentType", judgmentType)
  if (courtType) params.set("courtType", courtType)

  return APIService.get<null, DocumentSignatureType[]>(`/documents/signatures?${params}`)
}

const count = () => APIService.get<null, number>(`/documents/signatures/count`)

const getById = (id: string) =>
  APIService.get<null, DocumentSignatureType>(`/documents/signatures/${id}`)

const getFavourites = (ids: string[]) =>
    APIService.get<null, Page<DocumentSignatureType>>(`/documents/signatures/favourites?ids=${ids.join(",")}`)

const getFileById = (id: string) => APIService.get<null, any>(`/documents/signatures/${id}/file`)

const generateFileUrl = (id: string) => `${API_URL}/documents/signatures/${id}/file`

const searchJudges = (q: string) =>
  APIService.get<null, Page<DocumentSignatureJudge>>(`/documents/signatures/judges?q=${q}`)

const searchDivision = (q: string, divisionType: string[], divisionCourtType: string[]) =>
  APIService.get<null, Page<DocumentSignatureDivision>>(`/documents/signatures/divisions?q=${q}&divisionType=${divisionType.join(",")}&divisionCourtType=${divisionCourtType.join(",")}`)

export const DocumentSignatureService = {
  count,
  get,
  getFavourites,
  getById,
  getFileById,
  generateFileUrl,
  searchJudges,
  searchDivision,
}
