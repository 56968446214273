const getObject = (name: string) => (typeof window !== "undefined" ? localStorage.getItem(name) : null)
const setObject = (name: string, value: string) =>
  typeof window !== "undefined" ? localStorage.setItem(name, value) : () => {}
const removeObject = (name: string) =>
    typeof window !== "undefined" ? localStorage.removeItem(name) : () => {}

export const LocalStorageService = {
  getObject,
  setObject,
  removeObject,
}
