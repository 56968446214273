import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import Button from "components/atoms/button"
import { Box, Row } from "components/atoms/_atoms"
import { P, Title } from "components/atoms/typography"
import Input from "components/atoms/input"
import Alert from "components/molecules/alert"
import { RootState } from "store/store"
import { SentryService, UserService } from "services"
import { successVerify } from "store/auth"

const UserView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.auth)
  const { profile } = useSelector((state: RootState) => state.profile)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: user?.username,
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
  })

  const [response, setResponse] = useState<boolean | string>(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (el: any) => {
    try {
      setLoading(true)
      const user = await UserService.updateUser(el)
      dispatch(successVerify(user))
      setResponse("success")
    } catch (e) {
      setResponse("error")
      SentryService.error("[ERROR UserService.updateUser]:", e)
    } finally {
      setLoading(false)
      setTimeout(() => {
        setResponse(false)
      }, 3000)
    }
  }, [])

  return (
    <Wrapper>
      <Box>
        <Title marginBottom="XS">Dane konta</Title>
        <P small marginBottom="0">
          Edytuj swoje dane konta
        </P>
      </Box>
      <Content>
        {response === "error" && (
          <Alert type="error" small marginBottom="S">
            Nie udało się zmienić danych konta.
          </Alert>
        )}
        {response === "success" && (
          <Alert type="success" small marginBottom="S">
            Pomyślnie zmieniono dane konta.
          </Alert>
        )}
        <Input
          name="username"
          label={"E-mail*"}
          error={errors.username}
          rhf={register("username", {
            required: true,
          })}
          marginBottom="S"
          disabled={true}
        />
        <StyledGrid marginBottom="S">
          <Input
            name="firstName"
            label={"Imię*"}
            error={errors.firstName}
            rhf={register("firstName", {
              required: true,
            })}
            marginBottom="S"
          />
          <Input
            name="lastName"
            label={"Nazwisko*"}
            error={errors.lastName}
            rhf={register("lastName", {
              required: true,
            })}
            marginBottom="S"
          />
        </StyledGrid>
        <Row justifyContent="flex-end">
          <Button loading={loading} icon="save" onClick={handleSubmit(onSubmit)}>
            Zapisz
          </Button>
        </Row>
      </Content>
    </Wrapper>
  )
}

export default UserView

const StyledGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
`

const Content = styled(Box)`
  width: 100%;
  max-width: 750px;
`

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M} 0;
  display: flex;
  justify-content: space-between;
  grid-gap: ${({ theme }) => theme.space.M};

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
