import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import Button from "components/atoms/button"
import { Box, Row } from "components/atoms/_atoms"
import { P, Title } from "components/atoms/typography"
import Input from "components/atoms/input"
import Alert from "components/molecules/alert"
import { RootState } from "store/store"
import { SentryService, UserService } from "services"
import { PASSWORD_REGEX } from "utils/regex"

const PasswordView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.auth)
  const { profile } = useSelector((state: RootState) => state.profile)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm()

  const [response, setResponse] = useState<boolean | string>(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (el: any) => {
    if (el.newPassword !== el.newPassword2) {
      setError("newPassword2", {
        type: "custom",
        message: "Hasła się od siebie różnią",
      })
      return
    }

    try {
      setLoading(true)
      await UserService.updatePassword(el.oldPassword, el.newPassword)
      setResponse("success")
    } catch (e) {
      setResponse("error")
      SentryService.error("[ERROR UserService.updatePassword]:", e)
    } finally {
      reset()
      setLoading(false)
      setTimeout(() => {
        setResponse(false)
      }, 3000)
    }
  }, [reset])

  return (
    <Wrapper>
      <Box>
        <Title marginBottom="XS">Zmiana hasła</Title>
        <P small marginBottom="0">
          Edytuj swoje hasło do konta
        </P>
      </Box>
      <Content>
        {response === "error" && (
          <Alert type="error" small marginBottom="S">
            Nie udało się zmienić Twojego hasła - sprawdź ponownie podane hasło.
          </Alert>
        )}
        {response === "success" && (
          <Alert type="success" small marginBottom="S">
            Pomyślnie zmieniono hasło do Twojego konta.
          </Alert>
        )}
        <Input
          name="oldPassword"
          label={"Stare hasło"}
          type="password"
          error={errors.oldPassword}
          rhf={register("oldPassword", {
            required: true,
          })}
          marginBottom="S"
        />
        <Input
          name="newPassword"
          label={"Nowe hasło*"}
          type="password"
          error={errors.newPassword}
          rhf={register("newPassword", {
            required: true,
            pattern: {
              value: PASSWORD_REGEX,
              message:
                "Niepoprawny format hasła - min. 8 znaków w tym jedna wielka litera i jedna cyfra",
            },
          })}
          marginBottom="S"
        />
        <Input
          name="newPassword2"
          label={"Powtórz nowe hasło*"}
          type="password"
          error={errors.newPassword2}
          rhf={register("newPassword2", {
            required: true,
            pattern: {
              value: PASSWORD_REGEX,
              message:
                "Niepoprawny format hasła - min. 8 znaków w tym jedna wielka litera i jedna cyfra",
            },
          })}
          marginBottom="S"
        />
        <Row justifyContent="flex-end">
          <Button loading={loading} icon="save" onClick={handleSubmit(onSubmit)}>
            Zapisz
          </Button>
        </Row>
      </Content>
    </Wrapper>
  )
}

export default PasswordView

const Content = styled(Box)`
  width: 100%;
  max-width: 750px;
`

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M} 0;
  display: flex;
  justify-content: space-between;
  grid-gap: ${({ theme }) => theme.space.M};

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
