import React from "react"
import styled from "styled-components"
import { Box, Row } from "components/atoms/_atoms"
import { Label, Title } from "components/atoms/typography"
import { ShortHistoryType } from "services/types"
import { formatDate } from "utils/date/date"
import Link from "components/atoms/link"

const HistoryBlock = ({ data }: { data: ShortHistoryType }) => {
  return (
    <>
      <Wrapper>
        <Content>
          <Row alignItems="center" height="100%">
            <Title margin="0">{data?.title}</Title>
          </Row>
          <Row alignItems="center" height="100%">
            <Label margin="0">{formatDate(data.dtCreated, "dd/MM/yyyy HH:MM")}</Label>
          </Row>
          <Link styledType="primary" to={data.id} icon="eye" type="secondary">
            Szczegóły
          </Link>
        </Content>
      </Wrapper>
    </>
  )
}

export default HistoryBlock

const Content = styled(Box)`
  padding: ${({ theme }) => theme.space.XS};
  padding-left: ${({ theme }) => theme.space.S};
  display: grid;
  grid-template-columns: 4fr 1fr max-content;
`

const Wrapper = styled(Box)<{
  suggested?: boolean
}>`
  background: white;
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  border: 1px solid white;
  overflow: hidden;
`
