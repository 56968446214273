import React, { ReactNode } from "react"
import styled, { css } from "styled-components"
import { Alert as MAlert } from "@mui/material"
import { Box, common } from "components/atoms/_atoms";
import { BaseStyles } from "types/base-styles";

export default function Alert({ type = "error", small, children, ...rest }: BaseStyles & {
    type: "success" | "warning" | "error"
    small?: boolean
    children: ReactNode
}) {
    return (
        <Wrapper type={type} small={small} {...rest}>
            <MAlert severity={type}>{children}</MAlert>
        </Wrapper>
    )
}

const Wrapper = styled(Box)<{
    type: string
}>`
  * {
    font-family: 'Manrope' !important;
  }
  
  ${common}

  .MuiPaper-root {
    align-items: center;
  }
  

  .MuiAlert-icon {
    padding: 0;
    font-size: ${({ theme }) => theme.font.L};
  }

  .MuiAlert-message {
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: ${({ theme }) => theme.font.M};
  }

  a {
    text-decoration: underline;
  }

  ${({ small }) =>
    small &&
    css`
      .MuiAlert-message {
        padding: 3px 0;
        font-size: ${({ theme }) => theme.font.S};
      }
    `};

  .MuiAlert-message,
  .MuiAlert-icon {
    ${({ theme, type }) =>
    type === "error" &&
    css`
        color: ${theme.color.red} !important;
      `};

    ${({ theme, type }) =>
    type === "warning" &&
    css`
        color: ${theme.color.orange} !important;
      `};

    ${({ theme, type }) =>
    type === "success" &&
    css`
        color: ${theme.color.green} !important;
      `};
  }
`
