import { configureStore } from "@reduxjs/toolkit"
import auth, { errorVerify, initVerify, successVerify } from "./auth"
import profile, { setProfile, setProfileIds } from "./profile"
import folders, { setFolders, initGetFolders } from "./folders"
import search from "./search"
import chat from "./chat"
import ui from "./ui"
import {
  LocalStorageService,
  ProfileService,
  PublicService,
  SentryService,
  UserService,
  UserFolderService,
} from "services"
import { getQuery } from "utils/queryHelper"

export const store = configureStore({
  reducer: {
    auth: auth,
    profile: profile,
    folders: folders,
    search: search,
    chat: chat,
    ui: ui,
  },
})

const { dispatch } = store

const verifyUser = async () => {
  const query = getQuery()
  if (query.get("token")) {
    await LocalStorageService.setObject("token", `${query.get("token")}`)
  }

  dispatch(initVerify())
  let user = null
  try {
    user = await PublicService.verify()
  } catch (e) {
    SentryService.error("[ERROR PublicService.verify]:", e)
    LocalStorageService.removeObject("token")
    LocalStorageService.removeObject("userId")
    LocalStorageService.removeObject("profileId")
    dispatch(errorVerify())
    return
  }

  let profileIds = null
  try {
    profileIds = await UserService.getProfiles(user.id)
    dispatch(setProfileIds(profileIds))
  } catch (e) {
    SentryService.error("[ERROR UserService.getProfiles]:", e)
    dispatch(errorVerify())
    return
  }

  if (profileIds && profileIds[0]) {
    LocalStorageService.setObject("profileId", profileIds[0])
    try {
      const profile = await ProfileService.getProfile(profileIds[0])
      dispatch(setProfile(profile))
    } catch (e) {
      SentryService.error("[ERROR ProfileService.getProfile]:", e)
      dispatch(errorVerify())
      return
    }
  }

  dispatch(successVerify(user))

  dispatch(initGetFolders())
  UserFolderService.getFolders()
      .then((folders) => dispatch(setFolders(folders)))
      .catch((e) => {
        SentryService.error("[ERROR UserFolderService.getFolders]:", e)
      })
}

verifyUser()

export type RootState = ReturnType<typeof store.getState>
