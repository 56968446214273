import { MessageType } from "components/molecules/chat/types"

export const makeHistory = (messages: MessageType[]) => {
  const filteredData = messages
    .filter(item => item.id !== "0")
    .filter(item => item.type !== "system")
  const grouped: { [key: string]: string[] } = {}

  // Grupowanie obiektów po id
  for (const obj of filteredData) {
    if (!grouped[obj.id]) {
      grouped[obj.id] = []
    }
    grouped[obj.id].push(obj.message)
  }

  const result: string[][] = Object.values(grouped)
  return result
}

export const getHumanMessages = (messages: MessageType[]) => {
  return messages.filter(item => item.type === "human")
}

export function removeDuplicatesByMetadataId(array: any): any[] {
  const seen = new Set()
  return array.filter((item: any) => {
    const value = item?.metadata?.id
    if (seen.has(value)) {
      return false
    } else {
      seen.add(value)
      return true
    }
  })
}
