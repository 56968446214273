import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { Box, Row } from "components/atoms/_atoms"
import { ProfileService } from "services/ProfileService"
import { RootState } from "store/store"
import { UserType } from "services/types"
import LoaderBlock from "components/shared/blocks/LoaderBlock"
import UserBlock from "components/shared/blocks/UserBlock"
import { Pagination } from "components/molecules/pagination"
import Button from "components/atoms/button"
import Modal from "components/molecules/modal"
import { SentryService } from "services"
import Alert from "components/molecules/alert"
import Input from "components/atoms/input"
import { EMAIL_REGEX } from "utils/regex"

const AddUserContent = ({
  setShow,
  refresh,
}: {
  setShow: (e: boolean) => void
  refresh: () => void
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const [response, setResponse] = useState<boolean | string>(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (el: any) => {
    try {
      setLoading(true)
      const user = await ProfileService.addUser(el)
      setResponse("success")
      setLoading(false)
      setResponse(false)
      setShow(false)
      refresh()
    } catch (e) {
      setLoading(false)
      setResponse("error")
      SentryService.error("[ERROR UserService.updateUser]:", e)
      setTimeout(() => {
        setResponse(false)
      }, 3000)
    }
  }, [])

  return (
    <Box>
      {response === "error" && (
        <Alert type="error" small marginBottom="S">
          Nie udało się dodać użytkownika - spróbuj podać inny adres e-mail.
        </Alert>
      )}
      {response === "success" && (
        <Alert type="success" small marginBottom="S">
          Pomyślnie dodano użytkownika do profilu.
        </Alert>
      )}
      <Input
        name="username"
        label={"E-mail*"}
        error={errors.username}
        rhf={register("username", {
          required: true,
          pattern: {
            value: EMAIL_REGEX,
            message: "Niepoprawny format email",
          },
        })}
        marginBottom="S"
      />
      <Input
        name="firstName"
        label={"Imię*"}
        error={errors.firstName}
        rhf={register("firstName", {
          required: true,
        })}
        marginBottom="S"
      />
      <Input
        name="lastName"
        label={"Nazwisko*"}
        error={errors.lastName}
        rhf={register("lastName", {
          required: true,
        })}
        marginBottom="S"
      />
      <Row justifyContent="flex-end">
        <Button loading={loading} icon="plus" onClick={handleSubmit(onSubmit)}>
          Dodaj
        </Button>
      </Row>
    </Box>
  )
}

const UsersView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.auth)
  const { profile } = useSelector((state: RootState) => state.profile)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: user?.username,
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
  })

  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState<UserType[]>([])

  const fetchData = async (newPage: number) => {
    try {
      setLoading(true)
      const { content, totalPages } = await ProfileService.getUsers(newPage)
      setTotalPages(totalPages)
      setData(content)
    } catch (e) {
      SentryService.error("[ERROR ProfileService.getUsers]:", e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(page)
  }, [page])

  return (
    <>
      <Wrapper>
        <Row justifyContent="flex-end">
          <Button icon="plus" onClick={() => setModal(true)}>
            Dodaj użytkownika
          </Button>
        </Row>
        <Content>
          {loading && (
            <>
              <LoaderBlock height="80px" />
              <LoaderBlock height="80px" />
              <LoaderBlock height="80px" />
            </>
          )}
          {!loading &&
            data.length > 0 &&
            data.map((el, index) => (
              <UserBlock key={index} data={el} refresh={() => fetchData(page)} />
            ))}
          {!loading && data.length > 0 && (
            <Pagination defaultPage={page + 1} totalPages={totalPages} onChange={e => setPage(e - 1)} />
          )}
        </Content>
      </Wrapper>
      <Modal
        icon="plus"
        show={modal}
        setShow={setModal}
        title="Dodaj użytkownika"
        text="Dodaj nowego użytkownika do swojego profilu firmowego."
      >
        <AddUserContent setShow={setModal} refresh={() => fetchData(0)} />
      </Modal>
    </>
  )
}

export default UsersView

const Content = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.S};
`

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M} 0;
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.M};
`
