import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { LocalStorageService, SentryService, UserService } from "services"
import { logout, successLogin } from "store/auth"
import { initProfileState } from "store/profile"
import { RootState } from "store/store"
import Modal from "components/molecules/modal"
import Alert from "components/molecules/alert"
import Input from "components/atoms/input"
import { PASSWORD_REGEX } from "utils/regex"
import Button from "components/atoms/button"

const ActivateAccount = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm()

  const { isVerifying, isLoggingIn, isLoginError } = useSelector((state: RootState) => state.auth)

  const [response, setResponse] = useState<boolean | string>(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(
    async (el: any) => {
      if (el.newPassword !== el.newPassword2) {
        setError("newPassword2", {
          type: "custom",
          message: "Hasła się od siebie różnią",
        })
        return
      }

      try {
        setLoading(true)
        const user = await UserService.setPassword(el.newPassword)
        dispatch(successLogin(user))
      } catch (e) {
        setResponse("error")
        SentryService.error("[ERROR UserService.setPassword]:", e)
      } finally {
        reset()
        setLoading(false)
        setTimeout(() => {
          setResponse(false)
        }, 3000)
      }
    },
    [reset],
  )

  const handleLogOut = useCallback(() => {
    LocalStorageService.removeObject("token")
    dispatch(logout())
    dispatch(initProfileState())
  }, [])

  return (
    <Modal
      show={true}
      icon="shield"
      title="Aktywuj konto"
      text="Ustaw swoje hasło i dokończ aktywację konta"
      maxWidth="400px"
      showBackground={false}
      helperButton={{
        text: "Wyloguj się",
        onClick: handleLogOut,
      }}
    >
      <>
        {response === "error" && (
          <Alert type="error" small marginBottom="S">
            Nie udało się ustawić hasła do Twojego konta - sprawdź ponownie podane hasło.
          </Alert>
        )}
        {response === "success" && (
          <Alert type="success" small marginBottom="S">
            Pomyślnie ustawiono hasło do Twojego konta.
          </Alert>
        )}
        <Input
          name="newPassword"
          label={"Podaj hasło*"}
          type="password"
          error={errors.newPassword}
          rhf={register("newPassword", {
            required: true,
            pattern: {
              value: PASSWORD_REGEX,
              message:
                "Niepoprawny format hasła - min. 8 znaków w tym jedna wielka litera i jedna cyfra",
            },
          })}
          marginBottom="S"
        />
        <Input
          name="newPassword2"
          label={"Powtórz hasło*"}
          type="password"
          error={errors.newPassword2}
          rhf={register("newPassword2", {
            required: true,
            pattern: {
              value: PASSWORD_REGEX,
              message:
                "Niepoprawny format hasła - min. 8 znaków w tym jedna wielka litera i jedna cyfra",
            },
          })}
          marginBottom="S"
        />
        <Button
          icon="save"
          width="100%"
          marginBottom="XS"
          loading={loading}
          onClick={handleSubmit(onSubmit)}
        >
          Zapisz
        </Button>
      </>
    </Modal>
  )
}

export default ActivateAccount
