import React from "react"
import styled from "styled-components"
import { Box, Row } from "components/atoms/_atoms"
import { Label } from "components/atoms/typography"
import Button from "components/atoms/button/Button"
import Tag from "components/atoms/tag"
import { formatDate } from "utils/date/date"
import { t } from "utils/translator"
import { AISourceDocument, AIService } from "services/AIService"
import { DocumentSignatureService } from "services/DocumentSignatureService"
import { IconButton } from "components/atoms/button"
import { useCustomQuery } from "hooks/useCustomQuery"
import QucikExplanationBlock from "components/shared/blocks/QucikExplanationBlock"

const SearchSimilarDocumentBlock = ({
  data,
  q,
  onClick,
}: {
  q: string
  data: AISourceDocument
  onClick: (e: string) => void
}) => {
  const { isLoading: isLoadingExplanation, data: explanation } = useCustomQuery<string>(
    { key: ["explanation", data.metadata.id, q] },
    () => AIService.quickAnswer({ id: data.metadata.id, message: q }),
  )

  return (
    <>
      <Wrapper>
        <Content>
          <Box marginBottom="XS">
            <Label icon="cv" iconColor="primary" type="secondary">
              {data.metadata.title}
            </Label>
            <TagsWrapper marginTop="XS">
              {data?.metadata.date && (
                <Tag
                  icon="clock"
                  iconColor="primary"
                  label={formatDate(data?.metadata.date)}
                  background="background"
                />
              )}
              <Tag
                icon="decision"
                iconColor="primary"
                label={t(data?.metadata.judgmentType)}
                background="background"
              />
              <Tag
                icon="court"
                iconColor="primary"
                label={t(data?.metadata.courtType)}
                background="background"
              />
            </TagsWrapper>
          </Box>
          <QucikExplanationBlock isLoading={isLoadingExplanation} marginBottom="XS">
            {explanation}
          </QucikExplanationBlock>
          <ButtonsWrapper justifyContent="flex-end">
            <a
              href={DocumentSignatureService.generateFileUrl(data.metadata.id)}
              target="_blank"
              download
              rel="noreferrer"
            >
              <IconButton
                background={"background"}
                color={"second"}
                borderRadius="borderRadius"
                size="45px"
                icon={"save"}
              />
            </a>
            <Button
              icon="eye"
              type="secondary"
              onClick={() => onClick(data.metadata.id)}
            >
              Zobacz szczegóły
            </Button>
          </ButtonsWrapper>
        </Content>
      </Wrapper>
    </>
  )
}

export default SearchSimilarDocumentBlock

const ButtonsWrapper = styled(Row)`
  grid-gap: ${({ theme }) => theme.space.XS};
`

const TagsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const Content = styled(Box)`
  padding: ${({ theme }) => theme.space.S};

  .text {
  }
`

const Wrapper = styled(Box)<{
  suggested?: boolean
}>`
  background: white;
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  border: 1px solid ${({ theme }) => theme.color.border};
  overflow: hidden;
`
