import React, { useState } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { Box, Row } from "components/atoms/_atoms"
import { Label, Title } from "components/atoms/typography"
import { UserType } from "services/types"
import { ProfileService, SentryService } from "services"
import { IconButton } from "components/atoms/button"
import { RootState } from "store/store"
import Tag from "components/atoms/tag"
import Button from "components/atoms/button/Button"

const UserBlock = ({ data, refresh }: { data: UserType; refresh: () => void }) => {
  const { user } = useSelector((state: RootState) => state.auth)
  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    if (
      confirm(`Czy na pewno chcesz usunąć użytkownika ${data?.firstName} ${data?.lastName}?`) ==
      true
    ) {
      try {
        setLoading(true)
        await ProfileService.deleteUser(data.id)
        refresh()
      } catch (e) {
        SentryService.error("[ERROR ProfileService.deleteUser]:", e)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleResendActivation = async () => {
    try {
      await ProfileService.resendUserActivation(data.id)
      window.alert(`Pomyślnie wysłaliśmy email aktywacyjny dla ${data?.username}.`)
    } catch (e) {
      SentryService.error("[ERROR ProfileService.deleteUser]:", e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      <Content>
        <Box>
          <Row alignItems="center" marginBottom="XS">
            <Row
              background="backgroundDarker"
              borderRadius="35px"
              width="35px"
              height="35px"
              justifyContent="center"
              alignItems="center"
              marginRight="XS"
            >
              <Title fontSize="L" margin="0">
                {data?.firstName.substring(0, 1)}
                {data?.lastName.substring(0, 1)}
              </Title>
            </Row>
            <Row alignItems="center">
              <Title margin="0">
                {data?.firstName} {data?.lastName}
              </Title>
              {data?.status === "complete_user" && (
                <Tag
                  marginLeft="XS"
                  label="Oczekuja na aktywacje"
                  color="orange"
                  background={"orangeBackground"}
                />
              )}
            </Row>
          </Row>
          <Label color="third" type="secondary">
            {data?.username}
          </Label>
        </Box>
        <ButtonsWrapper justifyContent="flex-end">
          {data?.status === "complete_user" && (
            <Button type="secondary" onClick={handleResendActivation}>
              Wyślij email aktywacyjny
            </Button>
          )}
          {user?.id !== data.id && (
            <IconButton
              color="red"
              borderRadius="borderRadius"
              loading={loading}
              size="45px"
              icon="trash"
              onClick={handleDelete}
            />
          )}
        </ButtonsWrapper>
      </Content>
    </Wrapper>
  )
}

export default UserBlock

const ButtonsWrapper = styled(Box)`
  display: flex;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const Content = styled(Box)`
  padding: ${({ theme }) => theme.space.S};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Wrapper = styled(Box)<{
  suggested?: boolean
}>`
  background: white;
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  border: 1px solid white;
  overflow: hidden;
`
