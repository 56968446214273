import { QueryFunction, QueryKey, useQuery } from "@tanstack/react-query"

type Params = {
  key: QueryKey
}


export function useCustomQuery<T>(params: Params, func: QueryFunction<T, QueryKey, never>, enabled = true) {
  const { data, isError, isFetching, isLoading } = useQuery<T, Error>({
    enabled,
    queryKey: params.key,
    queryFn: func,
  })

  return {
    data,
    isError,
    isFetching,
    isLoading
  }
}
