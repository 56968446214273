import { APIService } from "./APIService"
import { UserType } from "./types";

const login = (username: string, password: string) =>
  APIService.post<null, UserType>(`/login`, {
    username,
    password,
  })

const verify = () => APIService.post<null, UserType>(`/verify`)

export const PublicService = {
  login,
  verify,
}
