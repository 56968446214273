import React, { useCallback, useState } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import Modal from "components/molecules/modal"
import { SentryService } from "services"
import Alert from "components/molecules/alert"
import Button, { IconButton } from "components/atoms/button"
import { Row } from "components/atoms/_atoms"
import Input from "components/atoms/input"
import { makeId } from "utils/id"
import { RootState } from "store/store"

const Rating = ({
  number,
  rating,
  onClick,
}: {
  number: number
  rating: number
  onClick: (e: number) => void
}) => {
  return (
    <IconButton
      background="none"
      iconSize="XXL"
      icon={rating >= number ? "heart-fill" : "heart"}
      color={rating >= number ? "primary" : "secondary"}
      onClick={() => onClick(number)}
    />
  )
}
const NPSRatingModal = ({ show, setShow }: { show: boolean; setShow: (e: boolean) => void }) => {
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const [response, setResponse] = useState<boolean | string>(false)
  const [loading, setLoading] = useState(false)
  const [rating, setRating] = useState(0)

  const { user } = useSelector((state: RootState) => state.auth)
  const { profile } = useSelector((state: RootState) => state.profile)

  const onSubmit = useCallback(
    async (el: any) => {
      try {
        setLoading(true)
        await axios.get(
          `https://hook.eu2.make.com/mdhuw3vcg3m8nn3x04f03ipls0f1umhg?id=${makeId()}&username=${
            user?.username
          }&profile=${profile?.name}&rating=${rating}&message=${el.message}`,
        )
        setLoading(false)
        setRating(0)
        reset()
        setShow(false)
      } catch (e) {
        setLoading(false)
        setResponse("error")
        SentryService.error("[ERROR Make.com Rating]:", e)
        setTimeout(() => {
          setResponse(false)
        }, 3000)
      }
    },
    [rating],
  )

  return (
    <Modal
      icon="heart"
      show={show}
      setShow={setShow}
      title="Oceń aplikację"
      text="Oceń naszą aplikację na podstawie własnych doświadczeń."
      maxWidth="400px"
    >
      <>
        {response === "error" && (
          <Alert type="error" small marginBottom="S">
            Nie udało się dodać oceny.
          </Alert>
        )}
        <Wrapper marginBottom="S">
          <Rating number={1} rating={rating} onClick={setRating} />
          <Rating number={2} rating={rating} onClick={setRating} />
          <Rating number={3} rating={rating} onClick={setRating} />
          <Rating number={4} rating={rating} onClick={setRating} />
          <Rating number={5} rating={rating} onClick={setRating} />
        </Wrapper>
        <Input
          name="message"
          label={"Dodatkowa informacja*"}
          error={errors.name}
          defaultValue={watch("message")}
          value={watch("message")}
          rhf={register("message", {
            required: true,
          })}
          multiline
          rows={4}
          marginBottom="S"
        />
        <Button loading={loading} width="100%" icon="send" onClick={handleSubmit(onSubmit)}>
          Wyślij
        </Button>
      </>
    </Modal>
  )
}

export default NPSRatingModal

const Wrapper = styled(Row)`
  display: flex;
  justify-content: center;
  grid-gap: ${({ theme }) => theme.space.S};
  align-items: center;
`
