import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import Modal from "components/molecules/modal"
import { SentryService, UserFolderService } from "services"
import { addFolder } from "store/folders"
import Alert from "components/molecules/alert"
import Input from "components/atoms/input"
import Button from "components/atoms/button"

const AddFolder = ({ setShow }: { setShow: (e: boolean) => void }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const dispatch = useDispatch()

  const [response, setResponse] = useState<boolean | string>(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (el: any) => {
    try {
      setLoading(true)
      const folder = await UserFolderService.addFolder(el.name)
      dispatch(addFolder(folder))
      setLoading(false)
      setResponse(false)
      setShow(false)
    } catch (e) {
      setLoading(false)
      setResponse("error")
      SentryService.error("[ERROR UserFolderService.addFolder]:", e)
      setTimeout(() => {
        setResponse(false)
      }, 3000)
    }
  }, [])

  return (
    <>
      {response === "error" && (
        <Alert type="error" small marginBottom="S">
          Nie udało się dodać folderu.
        </Alert>
      )}
      {response === "success" && (
        <Alert type="success" small marginBottom="S">
          Pomyślnie dodano nowy folder do profilu.
        </Alert>
      )}
      <Input
        name="name"
        label={"Nazwa folderu*"}
        error={errors.name}
        rhf={register("name", {
          required: true,
        })}
        marginBottom="S"
      />
      <Button loading={loading} width="100%" icon="plus" onClick={handleSubmit(onSubmit)}>
        Dodaj
      </Button>
    </>
  )
}

const AddFolderModal = ({ show, setShow }: { show: boolean; setShow: (e: boolean) => void }) => {
  return (
    <Modal
      icon="plus"
      show={show}
      setShow={setShow}
      title="Dodaj nowy folder"
      text="Dodaj nowy folder dla ulubionych dokumentów."
    >
      <AddFolder setShow={setShow} />
    </Modal>
  )
}

export default AddFolderModal
